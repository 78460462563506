import {
  CREATE_VENDOR,
  GET_ALL_VENDORS,
  UPDATE_VENDOR,
  CHANGE_VENDOR_STATUS,
  UPDATE_PROPERTIES,
  DELETE_VENDOR,
  ACTIVATE_TRANSLATION,
  OPEN_VENDOR_DIALOG,
  GET_BY_ID_VENDOR,
  RESET_FORM_VENDOR,
  RELOAD_VENDORS,
  GET_ALL_VENDORS_LIST,
  OPEN_VENDOR_CATEGORY_DIALOG,
  OPEN_CATEGORY_VENDOR_DIALOG,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  openCategoryDialog: false,
  form: {
    id: null,
    name: "",
    nameAR: "",
    websiteUrl: "",
    region: "",
    sequence: "",
    logoUrl: "",
    vendorShippingCost: "",
    maximumShippingCost: "",
    vendorDeliveryDays: "",
    activeTranslation: false,
    showDisclaimer: false,
    disclaimerEn: "",
    disclaimerAr: "",
    categories: [],
  },
  data: [],
  dataList: [],
  searchBody: {
    pageSize: 10,
  },
  getById: {},
  openVendorCategoryDialog: false,
  categoriesIds: [],
};

function vendorReducer(vendors = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_VENDOR:
      vendors.data = payload.data;
      return vendors;

    case GET_ALL_VENDORS:
      var _Vendor = {
        reload: vendors.reload,
        data: payload,
        searchBody: vendors.searchBody,
        getById: vendors.getById,
        form: vendors.form,
        openDialog: false,
        openCategoryDialog: false,
        openVendorCategoryDialog: false,
      };
      return _Vendor;

    case GET_ALL_VENDORS_LIST:
      vendors.dataList = payload;
      return vendors;

    case GET_BY_ID_VENDOR:
      vendors.form.id = payload.id;
      vendors.form.name = payload.name;
      vendors.form.nameAR = payload.nameAR;
      vendors.form.websiteUrl = payload.websiteUrl;
      vendors.form.region = payload.region;
      vendors.form.sequence = payload.sequence;
      vendors.form.logoUrl = payload.logoUrl;
      vendors.form.vendorShippingCost = payload.vendorShippingCost;
      vendors.form.maximumShippingCost = payload.maximumShippingCost;
      vendors.form.vendorDeliveryDays = payload.vendorDeliveryDays;
      vendors.form.activeTranslation = payload.activeTranslation;
      vendors.form.showDisclaimer = payload.showDisclaimer;
      vendors.form.disclaimerEn = payload.disclaimerEn;
      vendors.form.disclaimerAr = payload.disclaimerAr;
      vendors.form.categories = payload.categories.map((x) => x.id);
      return vendors;

    case RESET_FORM_VENDOR:
      vendors.form.id = null;
      vendors.form.name = "";
      vendors.form.nameAR = "";
      vendors.form.websiteUrl = "";
      vendors.form.region = "";
      vendors.form.sequence = "";
      vendors.form.logoUrl = "";
      vendors.form.vendorShippingCost = "";
      vendors.form.maximumShippingCost = "";
      vendors.form.vendorDeliveryDays = "";
      vendors.form.activeTranslation = false;
      vendors.form.showDisclaimer = false;
      vendors.form.disclaimerEn = "";
      vendors.form.disclaimerAr = "";
      return vendors;

    case UPDATE_VENDOR:
      var indexToChange = vendors.indexOf(
        vendors.data.filter((x) => x.id === payload.id)[0]
      );
      vendors.data.splice(indexToChange, 1, payload);
      return vendors;

    case OPEN_VENDOR_DIALOG:
      return { ...vendors, openDialog: !vendors.openDialog };

    case OPEN_CATEGORY_VENDOR_DIALOG:
      return { ...vendors, openCategoryDialog: !vendors.openCategoryDialog };

    case OPEN_VENDOR_CATEGORY_DIALOG:
      return {
        ...vendors,
        openVendorCategoryDialog: !vendors.openVendorCategoryDialog,
      };

    case RELOAD_VENDORS:
      return { ...vendors, reload: !vendors.reload };

    case UPDATE_PROPERTIES:
      return vendors;

    case CHANGE_VENDOR_STATUS:
      vendors.data.map((vendor) => {
        if (vendor.id === payload.id) vendor.isActive = !vendor.isActive;
        return vendor;
      });
      return vendors;

    case ACTIVATE_TRANSLATION:
      vendors.data.map((vendor) => {
        if (vendor.id === payload.id)
          vendor.ActiveTranslation = !vendor.ActiveTranslation;
        return vendor;
      });
      return vendors;

    case DELETE_VENDOR:
      vendors = vendors.data.filter((vendor) => vendor.id !== payload.id);
      return vendors;

    default:
      return vendors;
  }
}

export default vendorReducer;
