import {
  TextField,
  Grid,
  Stack,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  createPopUpPost,
  openDialog,
  resetForm,
  updatePopUpPost,
} from "src/actions/popUpPost";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { getGiftsCategoryList } from "src/actions/gifts/giftCategory";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import moment from "moment";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const PopUpPostForm = () => {
  const dispatch = useDispatch();

  const _PopUpPost = useSelector((state) => state.popUpPost);
  const pageNO = useSelector((state) => state.popUpPost.pageNo);
  const reload = useSelector((state) => state.popUpPost.loading);
  const _GiftCategory = useSelector(
    (state) => state.giftItemCategory.giftItemCategoryList
  );

  const [attachmentsFiles, setAttachmentsFiles] = useState([]);
  const [files, setFiles] = useState([]);

  const isAdd = _PopUpPost.form.id ? false : true;
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: _PopUpPost.form,
  });

  useEffect(() => {
    if (attachmentsFiles.length > 0) {
      setFiles(attachmentsFiles.map((files) => files.file));
      setValue("attachment", attachmentsFiles.map((files) => files.file)[0], {
        shouldDirty: true,
      });
    } else {
      setValue("attachmentId", watch("attachmentId"), {
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line no-use-before-define
  }, [attachmentsFiles, setValue, watch]);

  useEffect(() => {
    reset({ ..._PopUpPost.form });
  }, [_PopUpPost.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getGiftsCategoryList());
  }, [dispatch]);

  const onSubmit = (data) => {
    if (data.type === null) {
      dispatch(showErrorSnackbar("Please select type"));
    } else {
      const formData = new FormData();
      formData.append("titleAr", data.titleAr ?? "");
      formData.append("titleEn", data.titleEn ?? "");
      formData.append("descriptionAr", data.descriptionAr);
      formData.append("descriptionEn", data.descriptionEn);
      formData.append("expiredAt", moment(data.expiredAt).format("YYYY-MM-DD"));
      formData.append("isActive", data.isActive);
      formData.append("large", data.large);
      formData.append("type", data.type);
      formData.append("id", data.id);
      formData.append("productUrl", data.productUrl);
      if (
        (!watch("large") && watch("descriptionAr").length > 250) ||
        (!watch("large") && watch("descriptionEn").length > 250)
      ) {
        dispatch(
          showErrorSnackbar("Description must be less than 250 characters")
        );
      } else {
        if (isAdd) {
          formData.append("attachment", files[0]);
          dispatch(createPopUpPost(formData, pageNO, true));
        } else {
          if (attachmentsFiles.length > 0) {
            formData.append("attachment", files[0]);
          } else {
            formData.append("attachmentId", data.attachmentId);
          }
          dispatch(updatePopUpPost(formData, pageNO));
        }
      }
    }
  };

  const handleDialog = () => {
    dispatch(openDialog(null));
  };

  const handleChange = (event, newType) => {
    setValue("type", newType, { shouldValidate: true });
    if (watch("type") === 1) {
      setValue("productUrl", "");
    } else {
      setValue("giftItemCategoryId", null);
      setValue("giftItemId", null);
    }
  };

  return (
    <CustomDialog
      title={isAdd ? "Create Popup Post" : "Update Popup Post"}
      isOpen={_PopUpPost.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      Draggable={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <ToggleButtonGroup
                  color="primary"
                  size="small"
                  {...field}
                  onChange={handleChange}
                  exclusive
                >
                  <ToggleButton
                    value={0}
                    onClick={() =>
                      setValue("type", 0, { shouldValidate: true })
                    }
                  >
                    Global
                  </ToggleButton>
                  <ToggleButton
                    value={1}
                    onClick={() =>
                      setValue("type", 1, { shouldValidate: true })
                    }
                  >
                    Vertical
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="expiredAt"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    label="Expiration date"
                    value={field.value}
                    onChange={(newValue) => {
                      setValue("expiredAt", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        {...params}
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "YYYY-MM-DD",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="titleAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required={watch("large") ? true : false}
                  disabled={watch("large") ? false : true}
                  size="small"
                  label="Title Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="titleEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required={watch("large") ? true : false}
                  disabled={watch("large") ? false : true}
                  size="small"
                  label="Title English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="descriptionAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  multiline
                  rows={5}
                  size="small"
                  label="Description Arabic"
                  fullWidth
                  {...field}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\n{2,}/g, "\n");
                    const lineCount = (newValue.match(/\n/g) || []).length;
                    if (lineCount < 5) {
                      e.target.value = newValue;
                      field.onChange(e);
                    }
                  }}
                  inputProps={{
                    maxLength: watch("large") ? 500 : 250,
                  }}
                  helperText={
                    watch("large")
                      ? `${field.value.length}/500`
                      : `${field.value.length}/250`
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="descriptionEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  multiline
                  rows={5}
                  size="small"
                  label="Description English"
                  fullWidth
                  {...field}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\n{2,}/g, "\n");
                    const lineCount = (newValue.match(/\n/g) || []).length;
                    if (lineCount < 5) {
                      e.target.value = newValue;
                      field.onChange(e);
                    }
                  }}
                  inputProps={{
                    maxLength: watch("large") ? 500 : 250,
                  }}
                  helperText={
                    watch("large")
                      ? `${field.value.length}/500`
                      : `${field.value.length}/250`
                  }
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Controller
              name="url"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Attachment URL"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid> */}
          {watch("type") === 1 ? (
            <Grid item xs={12}>
              <Controller
                name="giftItemCategoryId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={watch("giftItemId") ? true : false}
                    options={_GiftCategory || []}
                    getOptionLabel={(option) => option.nameEn}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Category" />
                    )}
                    onChange={(event, value) => {
                      setValue("giftItemCategoryId", value ? value.id : null, {
                        shouldDirty: true,
                      });
                    }}
                    value={
                      _GiftCategory?.filter((x) => x.id === field.value)[
                        0 ?? null
                      ]
                    }
                  />
                )}
              />
            </Grid>
          ) : null}
          {watch("type") === 1 ? (
            <Grid item xs={12}>
              <Controller
                name="giftItemId"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    disabled={watch("giftItemCategoryId") ? true : false}
                    label="Gift Product Id"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          ) : null}
          {watch("type") === 0 ? (
            <Grid item xs={12}>
              <Controller
                name="productUrl"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="Product Url"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={6}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title={watch("isActive") ? "Active" : "Inactive "}
                  aria-label="active_deactivate"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isActive", e.target.checked);
                        }}
                      />
                    }
                    label="Is Active !"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="large"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title={watch("large") ? "Large Post" : "Small Post"}
                  aria-label="active_deactivate"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("large", e.target.checked);
                          if (!e.target.checked) {
                            setValue("titleAr", "");
                            setValue("titleEn", "");
                          }
                        }}
                      />
                    }
                    label="Is Large Post !"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          {isAdd ? null : (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Box
                  component="img"
                  src={_PopUpPost.form.url}
                  width={300}
                  sx={{ objectFit: "contain" }}
                  alt="attachment"
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Controller
              name="attachmentId"
              control={control}
              render={({ field }) => (
                <FilePond
                  required={watch("attachmentId") === "" ? true : false}
                  files={attachmentsFiles}
                  onupdatefiles={setAttachmentsFiles}
                  maxFiles={10}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  credits={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={reload}
              >
                Save
              </LoadingButton>
              {isAdd && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ..._PopUpPost.form })}
                >
                  Clear Text
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default PopUpPostForm;
