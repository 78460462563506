import {
  ITEM_CANCEL_REASONS_RESET_FORM,
  ITEM_CANCEL_REASONS_GET_BY_ID,
  ITEM_CANCEL_REASONS_PAGE_NUMBER,
  ITEM_CANCEL_REASONS_OPEN_DIALOG,
  ITEM_CANCEL_REASONS_RELOAD,
  ITEM_CANCEL_REASONS_GET_ALL,
  ITEM_CANCEL_REASONS_GET_ALL_LIST,
} from "../../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  saveItemCancelReasonPageNum: 1,
  pageSize: 10,
  form: {
    id: null,
    nameAR: "",
    nameEN: "",
  },
  itemCancelReasonData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  itemCancelReasonDataList: [],
  getById: {},
};

function itemCancelReasonReducer(itemCancelReason = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ITEM_CANCEL_REASONS_GET_ALL:
      itemCancelReason.itemCancelReasonData.data = payload.data;
      itemCancelReason.itemCancelReasonData.total = payload.pageCount;
      itemCancelReason.itemCancelReasonData.rowCount = payload.rowCount;
      return itemCancelReason;

    case ITEM_CANCEL_REASONS_GET_ALL_LIST:
      itemCancelReason.itemCancelReasonDataList = payload;
      return itemCancelReason;

    case ITEM_CANCEL_REASONS_GET_BY_ID:
      itemCancelReason.form.id = payload.id;
      itemCancelReason.form.nameEN = payload.nameEN;
      itemCancelReason.form.nameAR = payload.nameAR;
      return itemCancelReason;

    case ITEM_CANCEL_REASONS_RESET_FORM:
      itemCancelReason.form.id = null;
      itemCancelReason.form.nameEN = "";
      itemCancelReason.form.nameAR = "";
      return itemCancelReason;

    case ITEM_CANCEL_REASONS_PAGE_NUMBER:
      return { ...itemCancelReason, saveItemCancelReasonPageNum: payload };

    case ITEM_CANCEL_REASONS_OPEN_DIALOG:
      return {
        ...itemCancelReason,
        openDialog: !itemCancelReason.openDialog,
      };

    case ITEM_CANCEL_REASONS_RELOAD:
      return { ...itemCancelReason, reload: !itemCancelReason.reload };

    default:
      return itemCancelReason;
  }
}

export default itemCancelReasonReducer;
