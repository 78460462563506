import moment from "moment";
import {
  RATING_QUESTIONS_RESET_FORM,
  RATING_QUESTIONS_GET_BY_ID,
  RATING_QUESTIONS_PAGE_NUMBER,
  RATING_QUESTIONS_OPEN_DIALOG,
  RATING_QUESTIONS_RELOAD,
  RATING_QUESTIONS_GET_ALL,
  RATING_QUESTIONS_GET_ALL_LIST,
  RATING_QUESTIONS_SEARCH_BODY,
  ORDER_RATING_GET_ALL,
  ORDER_RATING_RELOAD,
  ORDER_RATING_PAGE_NUMBER,
  ORDER_RATING_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  searchBody: {
    pageSize: 15,
    question: "",
    isActive: null,
    type: 2,
  },
  form: {
    id: null,
    questionAr: "",
    questionEn: "",
    type: null,
    isActive: true,
  },
  ratingQuestionsData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  ratingQuestionsDataList: [],
  getById: {},
  orderRatingReload: false,
  orderRatingPageNo: 0,
  orderRatingSearchBody: {
    pageSize: 15,
    orderNumber: "",
    phoneNumber: "",
    rate: "",
    fromDate: moment().format("2024-01-01"),
  },
  orderRatingData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
};

function ratingQuestionsReducer(ratingQuestions = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RATING_QUESTIONS_GET_ALL:
      ratingQuestions.ratingQuestionsData.data = payload.data;
      ratingQuestions.ratingQuestionsData.total = payload.pageCount;
      ratingQuestions.ratingQuestionsData.rowCount = payload.rowCount;
      return ratingQuestions;

    case ORDER_RATING_GET_ALL:
      ratingQuestions.orderRatingData.data = payload.data;
      ratingQuestions.orderRatingData.total = payload.pageCount;
      ratingQuestions.orderRatingData.rowCount = payload.rowCount;
      return ratingQuestions;

    case RATING_QUESTIONS_GET_ALL_LIST:
      ratingQuestions.ratingQuestionsDataList = payload;
      return ratingQuestions;

    case RATING_QUESTIONS_GET_BY_ID:
      ratingQuestions.form = {
        ...payload,
        vendorId: payload.vendor?.id,
      };
      return ratingQuestions;

    case RATING_QUESTIONS_RESET_FORM:
      ratingQuestions.form.id = null;
      ratingQuestions.form.name = "";
      ratingQuestions.form.price = "";
      ratingQuestions.form.vendorId = "";
      ratingQuestions.form.url = "";
      ratingQuestions.form.imageUrl = "";
      return ratingQuestions;

    case RATING_QUESTIONS_PAGE_NUMBER:
      return { ...ratingQuestions, pageNo: payload };

    case RATING_QUESTIONS_SEARCH_BODY:
      return { ...ratingQuestions, searchBody: payload };

    case RATING_QUESTIONS_OPEN_DIALOG:
      return {
        ...ratingQuestions,
        openDialog: !ratingQuestions.openDialog,
      };

    case RATING_QUESTIONS_RELOAD:
      return { ...ratingQuestions, reload: !ratingQuestions.reload };

    case ORDER_RATING_RELOAD:
      return {
        ...ratingQuestions,
        orderRatingReload: !ratingQuestions.orderRatingReload,
      };

    case ORDER_RATING_PAGE_NUMBER:
      return { ...ratingQuestions, orderRatingPageNo: payload };

    case ORDER_RATING_SEARCH_BODY:
      return { ...ratingQuestions, orderRatingSearchBody: payload };

    default:
      return ratingQuestions;
  }
}

export default ratingQuestionsReducer;
