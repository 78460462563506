import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  TextField,
  InputAdornment,
  Chip,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomDialog } from "src/components/CustomDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AiOutlineSearch } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteGiftCollections,
  getAllGiftCollections,
  getGiftCollectionsById,
  resetGiftCollectionsForm,
  saveGiftCollectionsPageNum,
  saveGiftCollectionsSearchBody,
} from "src/actions/gifts/collections";
import useCanShow from "src/components/CanShow";

export default function CollectionsTable() {
  const dispatch = useDispatch();

  const updateGiftCollectionRole = useCanShow("62-05");
  const deleteGiftCollectionRole = useCanShow("62-06");

  const _Collections = useSelector((state) => state.giftCollections.data);
  const loading = useSelector((state) => state.giftCollections.reload);
  const pageNo = useSelector((state) => state.giftCollections.collectionPageNo);
  const searchBody = useSelector((state) => state.giftCollections.searchBody);

  const [page, setPage] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openOrderReasonDialog, setOpenOrderReasonDialog] = useState(false);
  const [searchName, setSearchName] = useState(searchBody.name);

  const isForOrderiiNow = [
    { name: "Orderii Now", key: true },
    { name: "Gift", key: false },
  ];

  useEffect(() => {
    dispatch(saveGiftCollectionsPageNum(page + 1));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(getAllGiftCollections(page + 1, searchBody));
  }, [dispatch, page, searchBody]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  function deleteSelectedPromo(id) {
    setOpenDeleteDialog(false);
    dispatch(deleteGiftCollections(id, pageNo, searchBody)).then((x) => {
      dispatch(resetGiftCollectionsForm());
    });
  }

  // const isActive = [
  //   { name: "Active", key: true },
  //   { name: "Deactivate", key: false },
  // ];

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "sequence",
      headerName: "Sequence",
      flex: 0.5,
    },
    {
      field: "nameAr",
      headerName: "Name Arabic",
      flex: 1,
    },
    {
      field: "nameEn",
      headerName: "Name English",
      flex: 1,
    },
    {
      field: "items",
      headerName: "Items Count",
      flex: 1,
      renderCell: (params) => params.row.giftItems?.length?.toLocaleString(),
    },
    {
      field: "isForOrderiiNow",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.isForOrderiiNow ? "Orderii Now" : "Gifts"}
          color={params.row.isForOrderiiNow ? "success" : "warning"}
        />
      ),
    },
    // {
    //   field: "isActive",
    //   headerName: "Is Active",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Chip
    //       label={params.row.isActive ? "Active" : "Inactive"}
    //       color={params.row.isActive ? "success" : "error"}
    //     />
    //   ),
    // },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={!updateGiftCollectionRole}
          showInMenu
          onClick={(e) => {
            dispatch(getGiftCollectionsById(params.row.id, true));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          hidden={!deleteGiftCollectionRole}
          showInMenu
          onClick={(e) => {
            setOpenDeleteDialog(true);
            setOpenOrderReasonDialog(params.row);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedPromo(openOrderReasonDialog.id)}
            selectedObject={openOrderReasonDialog.title}
            maxWidth="sm"
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveGiftCollectionsSearchBody({
                      ...searchBody,
                      name: searchName,
                    })
                  );
                  setPage(0);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={isForOrderiiNow}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Type" />}
              onChange={(event, value) => {
                dispatch(
                  saveGiftCollectionsSearchBody({
                    ...searchBody,
                    isForOrderiiNow: value ? value.key : null,
                    name: searchName,
                  })
                );
                setPage(0);
              }}
              value={
                isForOrderiiNow.filter(
                  (x) => x.key === searchBody.isForOrderiiNow
                )[0] ?? null
              }
            />
          </Grid>
          {/* <Grid item xs={2}>
            <Autocomplete
              disabled={loading}
              options={isActive}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="is Active !" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveGiftCollectionsSearchBody({
                    ...searchBody,
                    isActive: value ? value.key : null,
                  })
                );
                setPage(0);
              }}
              value={
                isActive.filter((x) => x.key === searchBody.isActive)[0] ?? null
              }
            />
          </Grid> */}
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setSearchName("");
                  dispatch(
                    saveGiftCollectionsSearchBody({
                      ...searchBody,
                      name: "",
                      isForOrderiiNow: null,
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 270px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _Collections?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  rowCount={_Collections?.rowCount}
                  pageSize={searchBody.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(pageSize) => {
                    dispatch(
                      saveGiftCollectionsSearchBody({
                        ...searchBody,
                        pageSize,
                      })
                    );
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
