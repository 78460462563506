import {
  OPEN_VENDORS_CATEGORY_DIALOG,
  GET_BY_ID_VENDORS_CATEGORY,
  GET_VENDORS_CATEGORY_LIST,
  GET_ALL_VENDORS_CATEGORY,
  SAVE_VENDORS_CATEGORY_PAGE_NO,
  RESET_VENDORS_CATEGORY_FORM,
  RELOAD_VENDORS_CATEGORY,
  SAVE_VENDORS_CATEGORY_SEARCH_BODY,
  OPEN_VENDORS_CATEGORIES_DIALOG,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  openVendorCategoriesDialog: false,
  form: {
    id: null,
    nameAr: "",
    nameEn: "",
    attachmentId: "",
  },
  data: [],
  vendorsCategoryList: [],
  searchBody: {
    pageSize: 15,
    name: "",
  },
  getById: {},
  saveVendorsCategoryPageNum: 0,
};

function vendorCategoryReducer(vendorsCategory = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_VENDORS_CATEGORY:
      var _vendorCategory = {
        reload: vendorsCategory.reload,
        data: payload,
        searchBody: vendorsCategory.searchBody,
        getById: vendorsCategory.getById,
        form: vendorsCategory.form,
        openDialog: false,
        openVendorCategoriesDialog: false,
        vendorsCategoryList: vendorsCategory.vendorsCategoryList,
        saveVendorsCategoryPageNum: vendorsCategory.saveVendorsCategoryPageNum,
      };
      return _vendorCategory;

    case GET_VENDORS_CATEGORY_LIST:
      vendorsCategory.vendorsCategoryList = payload;
      return vendorsCategory;

    case GET_BY_ID_VENDORS_CATEGORY:
      vendorsCategory.form.id = payload.id;
      vendorsCategory.form.nameAr = payload.nameAr;
      vendorsCategory.form.nameEn = payload.nameEn;
      vendorsCategory.form.imageUrl = payload.imageUrl;
      vendorsCategory.form.attachmentId = payload.attachmentId;
      vendorsCategory.form.vendorIds = payload.vendorIds;
      return vendorsCategory;

    case OPEN_VENDORS_CATEGORY_DIALOG:
      return { ...vendorsCategory, openDialog: !vendorsCategory.openDialog };

    case OPEN_VENDORS_CATEGORIES_DIALOG:
      return {
        ...vendorsCategory,
        openVendorCategoriesDialog: !vendorsCategory.openVendorCategoriesDialog,
      };

    case SAVE_VENDORS_CATEGORY_PAGE_NO:
      return { ...vendorsCategory, saveVendorsCategoryPageNum: payload };

    case RELOAD_VENDORS_CATEGORY:
      return { ...vendorsCategory, reload: !vendorsCategory.reload };

    case SAVE_VENDORS_CATEGORY_SEARCH_BODY:
      return { ...vendorsCategory, searchBody: payload };

    case RESET_VENDORS_CATEGORY_FORM:
      vendorsCategory.form.id = null;
      vendorsCategory.form.nameEn = "";
      vendorsCategory.form.nameAr = "";
      vendorsCategory.form.attachmentId = "";
      return vendorsCategory;

    default:
      return vendorsCategory;
  }
}

export default vendorCategoryReducer;
