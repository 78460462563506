import axios from "../axios";

class GiftCartService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`giftcart/${pageNo}?${params}`);
  }

  getById(id) {
    return axios.get(`/giftcart/getById/${id}`);
  }
  
  updateCallHistory(id) {
    return axios.put(`/giftcart/${id}`);
  }
}

export default new GiftCartService();
