import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  TextField,
  InputAdornment,
  Autocomplete,
  Chip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomDialog } from "src/components/CustomDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import host from "src/api/host";
import { AiOutlineSearch } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteItemSupplier,
  getAllItemSupplier,
  getItemSupplierById,
  resetItemSupplierForm,
  saveItemSupplierPageNum,
  saveItemSupplierSearchBody,
} from "src/actions/gifts/supplier";
import { showSuccessSnackbar } from "src/actions/snackbarMessages";
import { FaCopy } from "react-icons/fa";
import useCanShow from "src/components/CanShow";

export default function ItemSupplierTable() {
  const dispatch = useDispatch();

  const copySupplierIdRole = useCanShow("54-03");
  const editSupplierRole = useCanShow("54-04");
  const deleteSupplierRole = useCanShow("54-05");

  const _Supplier = useSelector((state) => state.itemSupplier.itemSupplierData);
  const loading = useSelector((state) => state.itemSupplier.reload);
  const pageNo = useSelector((state) => state.itemSupplier.pageNo);

  const [page, setPage] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openOrderReasonDialog, setOpenOrderReasonDialog] = useState(false);
  const searchBody = useSelector((state) => state.itemSupplier.searchBody);
  const [searchName, setSearchName] = useState(searchBody.name);

  useEffect(() => {
    dispatch(saveItemSupplierPageNum(page + 1));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(getAllItemSupplier(page + 1, searchBody));
  }, [dispatch, page, searchBody]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  function deleteSelectedPromo(id) {
    setOpenDeleteDialog(false);
    dispatch(deleteItemSupplier(id, pageNo, searchBody)).then((x) => {
      dispatch(resetItemSupplierForm());
    });
  }

  const isActive = [
    { name: "Active", key: true },
    { name: "Deactivate", key: false },
  ];

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "nameAr",
      headerName: "Name Arabic",
      flex: 1,
    },
    {
      field: "nameEn",
      headerName: "Name English",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "availability",
      headerName: "Working Days",
      flex: 1,
      renderCell: (params) => params.row.workTimeSchedule?.length,
    },
    {
      field: "province",
      headerName: "Province",
      flex: 1,
      renderCell: (params) => params.row.province?.nameUS,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      renderCell: (params) => params.row.city?.nameUS,
    },
    {
      field: "isActive",
      headerName: "Is Active",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.isActive ? "Active" : "Inactive"}
          color={params.row.isActive ? "success" : "error"}
        />
      ),
    },
    {
      field: "note",
      headerName: "Note",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.note}>
          <span>{params.row.note}</span>
        </Tooltip>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FaCopy size={22} />}
          label="Copy Supplier Id"
          hidden={!copySupplierIdRole}
          showInMenu
          onClick={(e) => {
            navigator.clipboard.writeText(params.row.id);
            dispatch(showSuccessSnackbar("Supplier Id Copied"));
          }}
        />,
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={!editSupplierRole}
          showInMenu
          onClick={(e) => {
            dispatch(getItemSupplierById(params.row.id, host + params.row.url));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          hidden={!deleteSupplierRole}
          showInMenu
          onClick={(e) => {
            setOpenDeleteDialog(true);
            setOpenOrderReasonDialog(params.row);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedPromo(openOrderReasonDialog.id)}
            selectedObject={openOrderReasonDialog.title}
            maxWidth="sm"
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveItemSupplierSearchBody({
                      ...searchBody,
                      name: searchName,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              disabled={loading}
              options={isActive}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="is Active !" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveItemSupplierSearchBody({
                    ...searchBody,
                    isActive: value ? value.key : null,
                  })
                );
                setPage(0);
              }}
              value={
                isActive.filter((x) => x.key === searchBody.isActive)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setSearchName("");
                  dispatch(
                    saveItemSupplierSearchBody({
                      ...searchBody,
                      name: "",
                      isActive: null,
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 270px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _Supplier?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  rowCount={_Supplier?.rowCount}
                  pageSize={10}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={[10]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
