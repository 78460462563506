import {
  TextField,
  Grid,
  Stack,
  Button,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { uploadAttachments } from "src/actions/uploadAttachments";
import {
  createGiftBrand,
  openGiftsBrandDialog,
  resetGiftsBrandForm,
  updateGiftsBrand,
} from "src/actions/gifts/brand";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const GiftBrandForm = () => {
  const dispatch = useDispatch();

  const giftItemBrand = useSelector((state) => state.giftItemBrand);
  const loading = useSelector((state) => state.giftItemBrand.reload);
  const searchBody = useSelector((state) => state.giftItemBrand.searchBody);
  const pageNo = useSelector(
    (state) => state.giftItemBrand.saveGiftItemBrandPageNum
  );
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    setValue,
    watch,
  } = useForm({
    defaultValues: giftItemBrand.form,
  });

  const [attachmentsFiles, setAttachmentsFiles] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (attachmentsFiles.length > 0) {
      setFiles(attachmentsFiles.map((files) => files.file));
      setValue("attachmentId", attachmentsFiles.map((files) => files.file)[0], {
        shouldDirty: true,
      });
    } else {
      setValue("attachmentId", watch("attachmentId"), {
        shouldDirty: true,
      });
    }
  }, [attachmentsFiles, setValue, watch]);

  const isAdd = giftItemBrand.form.id ? false : true;

  const handleDialog = () => {
    dispatch(openGiftsBrandDialog());
  };

  useEffect(() => {
    reset({ ...giftItemBrand.form });
  }, [reset, giftItemBrand.form]);

  useEffect(() => {
    return () => {
      dispatch(resetGiftsBrandForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", 8);
    if (isAdd) {
      dispatch(uploadAttachments(formData))
        .then((res) => {
          dispatch(
            createGiftBrand(
              {
                nameAr: data.nameAr,
                nameEn: data.nameEn,
                isActive: data.isActive,
                attachmentId: res.data.id,
              },
              {
                pageNo: pageNo + 1,
                pageSize: searchBody.pageSize,
                name: searchBody.name,
              }
            )
          ).then((x) => {
            reset({ ...giftItemBrand.form });
            dispatch(resetGiftsBrandForm());
            handleDialog();
          });
        })
        .catch((err) => {});
    } else {
      if (files[0]) {
        dispatch(uploadAttachments(formData))
          .then((res) => {
            dispatch(
              updateGiftsBrand(
                data.id,
                {
                  nameAr: data.nameAr,
                  nameEn: data.nameEn,
                  isActive: data.isActive,
                  attachmentId: res.data.id,
                },
                {
                  pageNo: pageNo + 1,
                  pageSize: searchBody.pageSize,
                  name: searchBody.name,
                }
              )
            ).then((x) => {
              reset({ ...giftItemBrand.form });
              dispatch(resetGiftsBrandForm());
              handleDialog();
            });
          })
          .catch((err) => {});
      } else {
        dispatch(
          updateGiftsBrand(
            data.id,
            {
              nameAr: data.nameAr,
              nameEn: data.nameEn,
              isActive: data.isActive,
              attachmentId: data.attachmentId,
            },
            {
              pageNo: pageNo + 1,
              pageSize: searchBody.pageSize,
              name: searchBody.name,
            }
          )
        ).then((x) => {
          reset({ ...giftItemBrand.form });
          dispatch(resetGiftsBrandForm());
          handleDialog();
        });
      }
    }
  };

  return (
    <CustomDialog
      title={isAdd ? "Create product brand" : "Update product brand"}
      isOpen={giftItemBrand.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="nameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  inputProps={{
                    minLength: 4,
                  }}
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="nameAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  inputProps={{
                    minLength: 4,
                  }}
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      onChange={(e) => {
                        setValue("isActive", e.target.checked, {
                          shouldDirty: true,
                        });
                      }}
                    />
                  }
                  label="Is Active"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="attachmentId"
              control={control}
              render={({ field }) => (
                <FilePond
                  required={watch("attachmentId") === "" ? true : false}
                  files={attachmentsFiles}
                  onupdatefiles={setAttachmentsFiles}
                  maxFiles={10}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  credits={false}
                />
              )}
            />
          </Grid>
          {!isAdd ? (
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                src={giftItemBrand.form?.imageUrl}
                width={300}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
              {
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ...giftItemBrand.form })}
                >
                  Clear Text
                </Button>
              }
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default GiftBrandForm;
