import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { SiTwilio } from 'react-icons/si';
import { AiOutlineMessage } from 'react-icons/ai';


const MessageBalance = (props) => (
  <Grid container spacing={1}>
    <Grid item lg={3} sm={6} xl={3} xs={12}>
      <Card
        sx={{ height: '100%' }}
        {...props}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                MESSAGE BIRD BALANCE
              </Typography>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {props.data ? props.data.messageBirdBalance?.toLocaleString() : ""} $
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: '#0089FF',
                  height: 56,
                  width: 56,
                  padding: 1,
                }}
              >
                <AiOutlineMessage size={30} />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
    <Grid item lg={3} sm={6} xl={3} xs={12}>
      <Card
        sx={{ height: '100%' }}
        {...props}
      >
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
              >
                TWILIO BALANCE
              </Typography>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {props.data ? props.data.twilioBalance?.toLocaleString() : ""} $
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: '#F22F46',
                  height: 56,
                  width: 56
                }}
              >
                <SiTwilio size={30} />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default MessageBalance;
