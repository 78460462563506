import { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  Box,
  Chip,
  IconButton,
  Card,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import {
  createRole,
  createRoleEndPoint,
  openDialog,
  resetForm,
} from "src/actions/dynamicAuth";
import { styled } from "@mui/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CloseIcon from "@mui/icons-material/Close";
import { RxSlash } from "react-icons/rx";

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const DynamicAuthForm = () => {
  const dispatch = useDispatch();

  const _DynamicAuth = useSelector((state) => state.dynamicAuth);

  const _DynamicAuthOrderiiEndPoints = useSelector(
    (state) => state.dynamicAuth.dynamicAuthOrderiiEndPointsData
  );

  const [loading, setLoading] = useState(false);

  const isAdd = _DynamicAuth.roleForm.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _DynamicAuth.roleForm,
  });

  useEffect(() => {
    reset({ ..._DynamicAuth.roleForm });
  }, [_DynamicAuth.roleForm, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const onSubmit = (data, event) => {
    event.preventDefault();
    if (isAdd) {
      setLoading(true);
      dispatch(
        createRole({
          name: data.name,
        })
      ).then((x) => {
        setLoading(false);
        dispatch(resetForm());
        reset({ ..._DynamicAuth.roleForm });
        setSearchEndPoints("");
        setSearchCode("");
        setSearchController("");
      });
    } else {
      setLoading(true);
      dispatch(
        createRoleEndPoint({
          roleId: data.id,
          oEndpointsIds: data.roleOEndpoints,
        })
      ).then((x) => {
        setLoading(false);
        dispatch(resetForm());
        reset({ ..._DynamicAuth.roleForm });
        setSearchEndPoints("");
        setSearchCode("");
        setSearchController("");
      });
    }
  };

  const [endPointsData, setEndPointsData] = useState([]);

  const [searchEndPoints, setSearchEndPoints] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const [searchController, setSearchController] = useState("");

  useEffect(() => {
    setEndPointsData(
      _DynamicAuthOrderiiEndPoints?.data.filter(
        (endPoint) =>
          endPoint.controller
            .toLowerCase()
            .startsWith(searchController.toLowerCase()) &&
          endPoint.code.toLowerCase().startsWith(searchCode.toLowerCase()) &&
          endPoint.userDefinedName
            .toLowerCase()
            .startsWith(searchEndPoints.toLowerCase())
      )
    );
  }, [
    searchEndPoints,
    searchCode,
    _DynamicAuthOrderiiEndPoints?.data,
    searchController,
  ]);

  const GroupedAccordions = (field) => {
    const groupedData = endPointsData?.reduce((result, item) => {
      if (!result[item.controller]) {
        result[item.controller] = [];
      }
      result[item.controller].push(item);
      return result;
    }, {});

    const accordions = Object.entries(groupedData).map(
      ([controller, items]) => (
        <StyledAccordion key={controller}>
          <StyledAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <Typography>
                {controller?.replace(/([a-z])([A-Z])/g, "$1 $2")}
              </Typography>
              <Stack
                direction="row"
                spacing={0}
                justifyContent="space-between"
                alignItems="center"
                textAlign="center"
              >
                <Chip
                  label={groupedData[controller]?.length}
                  color="primary"
                  size="small"
                />
                <Box>
                  <RxSlash size={18} />
                </Box>
                <Chip
                  label={
                    items.filter((item, index) => field.includes(item.id))
                      ?.length
                  }
                  color={
                    items.filter((item, index) => field.includes(item.id))
                      ?.length === groupedData[controller]?.length
                      ? "primary"
                      : "warning"
                  }
                  size="small"
                />
              </Stack>
            </Stack>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <FormGroup>
              {items.map((item, index) => (
                <Box key={index}>
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    key={index}
                    control={
                      <Checkbox
                        checked={field.includes(item.id) ? true : false}
                        onChange={() => {
                          if (field.includes(item.id)) {
                            setValue(
                              "roleOEndpoints",
                              field.filter((id) => id !== item.id)
                            );
                          } else {
                            setValue("roleOEndpoints", [...field, item.id]);
                          }
                        }}
                      />
                    }
                    label={
                      <Box sx={{ p: 0.75 }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Chip
                            label={item.code}
                            color="primary"
                            size="small"
                          />
                          <Stack spacing={0.5}>
                            <Typography>{item.userDefinedName}</Typography>
                            <Typography variant="caption" color="primary">
                              <span
                                style={{ color: "black" }}
                              >{`{{${item.methodType}}}`}</span>
                              {item.path}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                    }
                  />
                  <Divider />
                </Box>
              ))}
            </FormGroup>
          </StyledAccordionDetails>
        </StyledAccordion>
      )
    );

    return (
      <Card>
        <Stack justifyContent="center" padding={1}>
          <span style={{ textAlign: "center", fontSize: "20px" }}>
            All End Points
          </span>
          <Divider sx={{ marginBottom: 1 }} />
          {accordions}
        </Stack>
      </Card>
    );
  };

  const GroupedUserRolesAccordions = (field) => {
    const groupedData = field.reduce((result, item) => {
      if (!result[item?.oEndpoint.controller]) {
        result[item?.oEndpoint.controller] = [];
      }
      result[item?.oEndpoint.controller].push(item);
      return result;
    }, {});

    const sortedKeys = Object.keys(groupedData).sort();

    const accordions = sortedKeys.map((controller) => (
      <StyledAccordion key={controller}>
        <StyledAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            width="100%"
          >
            <Typography>
              {controller?.replace(/([a-z])([A-Z])/g, "$1 $2")}
            </Typography>
            <Box>
              <Chip
                label={groupedData[controller]?.length}
                color="primary"
                size="small"
              />
            </Box>
          </Stack>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {groupedData[controller]?.map((item, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={1}
              alignItems="center"
              padding={0.5}
            >
              <Chip label={item.oEndpoint?.code} color="primary" size="small" />
              <Box>{item.oEndpoint?.userDefinedName}</Box>
            </Stack>
          ))}
        </StyledAccordionDetails>
      </StyledAccordion>
    ));

    return (
      <Card sx={{ height: "100%" }}>
        <Stack padding={1}>
          <span style={{ textAlign: "center", fontSize: "20px" }}>
            Role End Points
          </span>
          <Divider sx={{ marginBottom: 1 }} />
          {accordions}
        </Stack>
      </Card>
    );
  };

  const handleDialog = () => {
    dispatch(openDialog());
    dispatch(resetForm());
    reset({ ..._DynamicAuth.roleForm });
  };

  return (
    <CustomDialog
      title={isAdd ? "ADD Roles" : "UPDATE Roles"}
      isOpen={_DynamicAuth.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
        setSearchEndPoints("");
        setSearchCode("");
        setSearchController("");
        // dispatch(resetForm());
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth={isAdd ? "sm" : "md"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name"
                  fullWidth
                  {...field}
                  value={field.value}
                  disabled={!isAdd}
                />
              )}
            />
          </Grid>
          {isAdd ? null : (
            <Grid item xs={3}>
              <TextField
                label="Search Controller"
                size="small"
                fullWidth
                value={searchController}
                onChange={(e) => {
                  setSearchController(e.target.value);
                }}
              />
            </Grid>
          )}
          {isAdd ? null : (
            <Grid item xs={3}>
              <TextField
                label="Search End Points"
                size="small"
                fullWidth
                value={searchEndPoints}
                onChange={(e) => {
                  setSearchEndPoints(e.target.value);
                }}
              />
            </Grid>
          )}
          {isAdd ? null : (
            <Grid item xs={3}>
              <TextField
                label="Search Code"
                size="small"
                fullWidth
                value={searchCode}
                onChange={(e) => {
                  setSearchCode(e.target.value);
                }}
              />
            </Grid>
          )}
          {isAdd ? null : (
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  setSearchEndPoints("");
                  setSearchCode("");
                  setSearchController("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
          {isAdd ? null : (
            <Grid item xs={12} md={12}>
              <Grid container spacing={4}>
                <Grid item xs={7}>
                  <Controller
                    name="roleOEndpoints"
                    control={control}
                    render={({ field }) => GroupedAccordions(field.value)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Box height={"100%"}>
                    {GroupedUserRolesAccordions(
                      _DynamicAuth.roleForm?.userRoles
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              {isAdd ? "ADD" : "Update User Permissions"}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default DynamicAuthForm;
