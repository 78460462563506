import moment from "moment";
import {
  ITEM_SUPPLIER_RESET_FORM,
  ITEM_SUPPLIER_GET_BY_ID,
  ITEM_SUPPLIER_PAGE_NUMBER,
  ITEM_SUPPLIER_OPEN_DIALOG,
  ITEM_SUPPLIER_RELOAD,
  ITEM_SUPPLIER_GET_ALL_LIST,
  ITEM_SUPPLIER_GET_ALL,
  ITEM_SUPPLIER_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  form: {
    id: null,
    nameEn: "",
    nameAr: "",
    note: "",
    isActive: true,
    phoneNumber: true,
    provinceId: null,
    cityId: null,
    workTimeSchedule: [
      {
        id: 6,
        weekDay: "Saturday",
        checked: false,
        startTime: null,
        endTime: null,
      },
      {
        id: 0,
        weekDay: "Sunday",
        checked: false,
        startTime: null,
        endTime: null,
      },
      {
        id: 1,
        weekDay: "Monday",
        checked: false,
        startTime: "",
        endTime: "",
      },
      {
        id: 2,
        weekDay: "Tuesday",
        checked: false,
        startTime: null,
        endTime: null,
      },
      {
        id: 3,
        weekDay: "Wednesday",
        checked: false,
        startTime: null,
        endTime: null,
      },
      {
        id: 4,
        weekDay: "Thursday",
        checked: false,
        startTime: null,
        endTime: null,
      },
      {
        id: 5,
        weekDay: "Friday",
        checked: false,
        startTime: null,
        endTime: null,
      },
    ],
  },
  itemSupplierData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  searchBody: {
    pageSize: 15,
    weekDay: "",
    isActive: null,
  },
  itemSupplierDataList: [],
  getById: {},
};

function itemSupplierReducer(itemSupplier = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ITEM_SUPPLIER_GET_ALL:
      itemSupplier.itemSupplierData.data = payload.data;
      itemSupplier.itemSupplierData.total = payload.pageCount;
      itemSupplier.itemSupplierData.rowCount = payload.rowCount;
      return itemSupplier;

    case ITEM_SUPPLIER_GET_ALL_LIST:
      itemSupplier.itemSupplierDataList = payload;
      return itemSupplier;

    case ITEM_SUPPLIER_GET_BY_ID:
      itemSupplier.form.id = payload.id;
      itemSupplier.form.nameEn = payload.nameEn;
      itemSupplier.form.nameAr = payload.nameAr;
      itemSupplier.form.isActive = payload.isActive;
      itemSupplier.form.note = payload.note;
      itemSupplier.form.phoneNumber = payload.phoneNumber;
      itemSupplier.form.provinceId = payload.province?.id;
      itemSupplier.form.cityId = payload.city?.id;
      itemSupplier.form.workTimeSchedule =
        itemSupplier.form.workTimeSchedule.map((item) => {
          const update = payload.workTimeSchedule?.find(
            (update) => update.weekDay === item.weekDay
          );
          return update
            ? {
                ...item,
                startTime: moment(update.startTime, "HH:mm"),
                endTime: moment(update.endTime, "HH:mm"),
                checked: true,
              }
            : item;
        });
      return itemSupplier;

    case ITEM_SUPPLIER_RESET_FORM:
      itemSupplier.form.id = null;
      itemSupplier.form.nameEn = "";
      itemSupplier.form.nameAr = "";
      itemSupplier.form.phoneNumber = "";
      itemSupplier.form.note = "";
      itemSupplier.form.provinceId = null;
      itemSupplier.form.cityId = null;
      itemSupplier.form.isActive = true;
      itemSupplier.form.workTimeSchedule = [
        {
          id: 1,
          weekDay: "Saturday",
          checked: false,
          startTime: null,
          endTime: null,
        },
        {
          id: 2,
          weekDay: "Sunday",
          checked: false,
          startTime: null,
          endTime: null,
        },
        {
          id: 3,
          weekDay: "Monday",
          checked: false,
          startTime: null,
          endTime: null,
        },
        {
          id: 4,
          weekDay: "Tuesday",
          checked: false,
          startTime: null,
          endTime: null,
        },
        {
          id: 5,
          weekDay: "Wednesday",
          checked: false,
          startTime: null,
          endTime: null,
        },
        {
          id: 6,
          weekDay: "Thursday",
          checked: false,
          startTime: null,
          endTime: null,
        },
        {
          id: 7,
          weekDay: "Friday",
          checked: false,
          startTime: null,
          endTime: null,
        },
      ];
      return itemSupplier;

    case ITEM_SUPPLIER_PAGE_NUMBER:
      return { ...itemSupplier, pageNo: payload };

    case ITEM_SUPPLIER_OPEN_DIALOG:
      return {
        ...itemSupplier,
        openDialog: !itemSupplier.openDialog,
      };

    case ITEM_SUPPLIER_SEARCH_BODY:
      return { ...itemSupplier, searchBody: payload };

    case ITEM_SUPPLIER_RELOAD:
      return { ...itemSupplier, reload: !itemSupplier.reload };

    default:
      return itemSupplier;
  }
}

export default itemSupplierReducer;
