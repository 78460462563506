import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  LinearProgress,
  Typography,
  Stack,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Autocomplete,
  Chip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import {
  deleteRatingQuestions,
  getAllRatingQuestions,
  getById,
  openDialog,
  resetForm,
  savePageNo,
  saveSearchBody,
} from "src/actions/ratingQuestions";
import SearchIcon from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import { CustomDialog } from "src/components/CustomDialog";
import { RATING_QUESTIONS_TYPES } from "src/utils/common";
import { MdDeleteForever } from "react-icons/md";
import useCanShow from "src/components/CanShow";
import theme from "src/theme";

export default function RatingQuestionsTable() {
  const dispatch = useDispatch();

  const updateRole = useCanShow("52-04");
  const deleteRole = useCanShow("52-05");

  const _RatingQuestionsData = useSelector(
    (state) => state.ratingQuestions.ratingQuestionsData
  );
  const _RatingQuestions = useSelector((state) => state.ratingQuestions);
  const searchFilter = useSelector((state) => state.ratingQuestions.searchBody);
  const reload = useSelector((state) => state.ratingQuestions.reload);
  const PageNo = useSelector((state) => state.ratingQuestions.pageNo);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(PageNo ? PageNo : 0);
  const [question, setQuestion] = useState(searchFilter.question ?? "");
  const [searchBody, setSearchBody] = useState({
    isActive: null,
    type: 2,
    pageSize: 15,
  });

  const isActive = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  useEffect(() => {
    dispatch(getAllRatingQuestions(page + 1, searchFilter));
  }, [searchFilter, page, dispatch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(savePageNo(newPage));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(saveSearchBody({ ...searchFilter, pageSize: newPageSize }));
  };

  function deleteSelectedItem() {
    setDeleteLoading(true);
    dispatch(
      deleteRatingQuestions(_RatingQuestions.form.id, page + 1, searchFilter)
    ).then((res) => {
      setDeleteLoading(false);
      dispatch(resetForm());
      setOpenDeleteDialog(false);
    });
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "questionEn",
      headerName: "Question English",
      flex: 2.5,
      renderCell: (params) => (
        <Tooltip title={params.row.questionEn} aria-label="edit">
          <Typography variant="body">{params.row.questionEn}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "questionAr",
      headerName: "Question Arabic",
      flex: 2.5,
      renderCell: (params) => (
        <Tooltip title={params.row.questionAr} aria-label="edit">
          <Typography variant="body">{params.row.questionAr}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "Is Active",
      flex: 0.8,
      renderCell: (params) =>
        params.row.isActive ? (
          <Chip color="success" label={"Active"} size="small" />
        ) : (
          <Chip color="secondary" label={"Inactive"} size="small" />
        ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">
          {moment(params.value).format("yyyy-MM-DD / hh:mma")}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={updateRole ? false : true}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((x) => {
              dispatch(openDialog());
            });
          }}
        />,
        <GridActionsCellItem
          icon={<MdDeleteForever color={theme.palette.error.dark} size={25} />}
          label="Delete"
          hidden={deleteRole ? false : true}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((res) => {
              setOpenDeleteDialog(true);
            });
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            maxWidth="sm"
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedItem()}
            selectedObject={_RatingQuestions.form.questionEn}
            loading={deleteLoading}
          ></CustomDialog>
        )}
      </Box>
      <PerfectScrollbar>
        <Grid container spacing={2} sx={{ padding: 3 }}>
          <Grid item xs={3}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Name"
              variant="outlined"
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchFilter,
                      question: question,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              disabled={reload}
              options={isActive}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Active !" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveSearchBody({
                    ...searchFilter,
                    isActive: value ? value.key : null,
                  })
                );
                setSearchBody({
                  ...searchBody,
                  isActive: value ? value.id : null,
                });
                setPage(0);
              }}
              value={
                isActive.filter((x) => x.key === searchFilter.isActive)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={RATING_QUESTIONS_TYPES || []}
              getOptionLabel={(option) => option.value}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Type" />}
              onChange={(event, value) => {
                dispatch(
                  saveSearchBody({
                    ...searchFilter,
                    type: value ? value.key : 2,
                  })
                );
                setSearchBody({
                  ...searchBody,
                  type: value ? value.key : null,
                });
              }}
              value={
                RATING_QUESTIONS_TYPES?.filter(
                  (x) => x.key === searchFilter.type
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <Stack direction="row" sx={{ justifyContent: "start" }}>
              <IconButton
                disabled={reload}
                onClick={() => {
                  dispatch(saveSearchBody({ ...searchBody }));
                  setPage(0);
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={reload}
                onClick={() => {
                  setPage(0);
                  setQuestion("");
                  dispatch(
                    saveSearchBody({
                      pageSize: 15,
                      question: "",
                      isActive: null,
                      type: 2,
                    })
                  );
                  setSearchBody({
                    pageSize: 15,
                    question: "",
                    isActive: null,
                    type: 2,
                  });
                }}
              >
                <Tooltip title="Clear filter">
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ minWidth: 1050 }}>
              <Box sx={{ height: { md: "calc(100vh - 290px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      paddingLeft: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      paddingLeft: "15px",
                    },
                  }}
                  loading={reload}
                  rows={_RatingQuestionsData.data}
                  columns={columns}
                  rowHeight={50}
                  disableSelectionOnClick
                  pagination
                  pageSize={searchFilter.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowCount={_RatingQuestionsData.rowCount}
                  paginationMode="server"
                  rowsPerPageOptions={[15, 25, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}
