import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Switch,
  Grid,
  CardContent,
  LinearProgress,
  Autocomplete,
  Chip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  changeStatus,
  changeTranslationStatus,
  deleteVendor,
  getAllList,
  getById,
  getByIdForUpdate,
  getVendorsCategoryList,
  openVendorCategoryDialog,
  resetForm,
} from "src/actions/vendors";
import { CustomDialog } from "src/components/CustomDialog";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { MdCategory, MdOutlineFlight } from "react-icons/md";
import { openShippingOptionsDialog } from "src/actions/shippingOptions";
import useCanShow from "src/components/CanShow";

export default function VendorsTable() {
  const dispatch = useDispatch();

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const updateVendorShippingOptionsRole = useCanShow("63-04");
  const updateVendorRole = useCanShow("33-02");
  const deleteVendorRole = useCanShow("33-09");
  const updateVendorCategoryRole = useCanShow("33-16");

  const [limit, setLimit] = useState(25);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const vendors = useSelector((state) => state.vendors.data);
  const vendor = useSelector((state) => state.vendors.form);
  const loading = useSelector((state) => state.vendors.reload);
  const loadingShippingOptions = useSelector(
    (state) => state.shippingOption.reload
  );
  const categoryList = useSelector(
    (state) => state.vendorsCategory.vendorsCategoryList
  );

  const [vendorData, setVendorData] = useState(vendors);
  const [searchVendor, setSearchVendor] = useState("");
  const [active, setActive] = useState(null);
  const [isTranslated, setIsTranslated] = useState(null);
  const [selectRegion, setSelectRegion] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    dispatch(getAllList());
    dispatch(getVendorsCategoryList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setVendorData(
      vendors.filter(
        (vendor) =>
          ((active === true && vendor.isActive) ||
            (active === false && !vendor.isActive) ||
            (active !== true && active !== false)) &&
          ((isTranslated === true && vendor.activeTranslation) ||
            (isTranslated === false && !vendor.activeTranslation) ||
            (isTranslated !== true && isTranslated !== false)) &&
          (selectRegion == vendor.vendorRegion || !selectRegion) &&
          ((showDisclaimer === true && vendor.showDisclaimer) ||
            (showDisclaimer === false && !vendor.showDisclaimer) ||
            (showDisclaimer !== true && showDisclaimer !== false)) &&
          (vendor.name.toLowerCase().startsWith(searchVendor.toLowerCase()) ||
            vendor.nameAR.startsWith(searchVendor)) &&
          (category == null ||
            vendor.categories.some((cat) => cat.id === category))
      )
    );
  }, [
    searchVendor,
    vendors,
    active,
    isTranslated,
    selectRegion,
    showDisclaimer,
    category,
  ]);

  function changeVendorState(id) {
    dispatch(changeStatus(id));
  }

  // function handleTranslation(id) {
  //   dispatch(changeTranslationStatus(id));
  // }

  function deleteSelectedVendor(id) {
    setOpenDeleteDialog(false);
    dispatch(deleteVendor(id)).then((x) => {
      dispatch(resetForm());
    });
  }

  const region = [
    { key: "TR", name: "TR" },
    { key: "US", name: "US" },
    { key: "AG", name: "AG" },
    { key: "EU", name: "EU" },
    { key: "WSM", name: "WSM" },
  ];

  const isActive = [
    { key: true, name: "Active" },
    { key: false, name: "Inactive" },
  ];

  const translation = [
    { key: true, name: "Active" },
    { key: false, name: "Inactive" },
  ];

  const disclaimer = [
    { key: true, name: "Active" },
    { key: false, name: "Inactive" },
  ];

  var columns = [
    {
      field: "number",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "sequence",
      headerName: "Sequence",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "nameAR",
      headerName: "Name Arabic",
      flex: 1,
    },
    {
      field: "logoUrl",
      headerName: "Logo",
      flex: 1,
      renderCell: (params) => (
        <a href={params.row.websiteUrl} target="_blank" rel="noreferrer">
          <img src={params.row.logoUrl} alt="Logo" width="85" />
        </a>
      ),
    },
    {
      field: "vendorRegion",
      headerName: "Region",
      flex: 1,
    },
    {
      field: "showDisclaimer",
      headerName: "Show Disclaimer !",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.showDisclaimer ? "Active" : "Inactive"}
          color={params.row.showDisclaimer ? "info" : "default"}
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Is Active !",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title="(Deactive) | (Active)" aria-label="active_deactive">
          <Switch
            disabled={
              _UserInfo.roleOEndpoint.map((data) => data.Code).includes("33-03")
                ? false
                : true
            }
            checked={params.row.isActive}
            onChange={(e) => changeVendorState(params.row.id)}
          />
        </Tooltip>
      ),
    },
    {
      field: "shippingOptions",
      headerName: "Shipping Options",
      width: 250,
      renderCell: (params) => (
        <Box width={250}>
          {params.row.shippingOptions.map((item) => (
            <Chip
              sx={{ margin: 0.5 }}
              label={item.nameEn}
              color={item.isDefault ? "primary" : "default"}
            />
          ))}
        </Box>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<MdOutlineFlight size={25} />}
          label="Shipping Types"
          hidden={updateVendorShippingOptionsRole ? false : true}
          showInMenu
          onClick={() => {
            dispatch(openShippingOptionsDialog(params.row));
          }}
        />,
        <GridActionsCellItem
          icon={<MdCategory size={25} />}
          label="Update Category"
          hidden={updateVendorCategoryRole ? false : true}
          showInMenu
          onClick={() => {
            dispatch(getById(params.row.id)).then(() => {
              dispatch(getVendorsCategoryList()).then(() => {
                dispatch(openVendorCategoryDialog());
              });
            });
          }}
        />,
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={updateVendorRole ? false : true}
          showInMenu
          onClick={() => {
            dispatch(getByIdForUpdate(params.row.id));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          hidden={deleteVendorRole ? false : true}
          showInMenu
          onClick={() => {
            dispatch(getById(params.row.id)).then((x) =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedVendor(vendor.id)}
            selectedObject={`${vendor.name} Vendor`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              label="Search Vendors"
              size="small"
              fullWidth
              value={searchVendor}
              onChange={(e) => {
                setSearchVendor(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1.7}>
            <Autocomplete
              options={region}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Vendor Region" />
              )}
              onChange={(event, value) => {
                setSelectRegion(value ? value.key : null);
              }}
              value={
                region.filter((x) => x.key == selectRegion)[0]
                  ? region.filter((x) => x.key == selectRegion)[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={1.7}>
            <Autocomplete
              options={isActive}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Vendor Status" />
              )}
              onChange={(event, value) => {
                setActive(value ? value.key : null);
              }}
              value={
                isActive.filter((x) => x.key == active)[0]
                  ? isActive.filter((x) => x.key == active)[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={1.7}>
            <Autocomplete
              options={translation}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Vendor Translation" />
              )}
              onChange={(event, value) => {
                setIsTranslated(value ? value.key : null);
              }}
              value={
                translation.filter((x) => x.key == isTranslated)[0]
                  ? translation.filter((x) => x.key == isTranslated)[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={1.7}>
            <Autocomplete
              options={disclaimer}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Vendor Disclaimer" />
              )}
              onChange={(event, value) => {
                setShowDisclaimer(value ? value.key : null);
              }}
              value={
                disclaimer.filter((x) => x.key == showDisclaimer)[0]
                  ? disclaimer.filter((x) => x.key == showDisclaimer)[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={1.7}>
            <Autocomplete
              options={categoryList}
              getOptionLabel={(option) => option.nameEn}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Vendor Category" />
              )}
              onChange={(event, value) => {
                setCategory(value ? value.id : null);
              }}
              value={categoryList.filter((x) => x.id === category)[0] ?? null}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setSearchVendor("");
                  setSelectRegion(null);
                  setIsTranslated(null);
                  setActive(null);
                  setShowDisclaimer(null);
                  setCategory(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 277px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      paddingLeft: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      paddingLeft: "15px",
                    },
                  }}
                  columns={columns}
                  rows={vendorData.map((row, index) => {
                    return { ...row, number: index + 1 };
                  })}
                  loading={loading || loadingShippingOptions}
                  disableSelectionOnClick
                  rowHeight={90}
                  pagination
                  paginationMode="client"
                  pageSize={limit}
                  rowsPerPageOptions={[25, 75, 100]}
                  onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
