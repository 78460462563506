import {
  USER_DEACTIVATION_REASONS_RESET_FORM,
  USER_DEACTIVATION_REASONS_GET_BY_ID,
  USER_DEACTIVATION_REASONS_PAGE_NUMBER,
  USER_DEACTIVATION_REASONS_OPEN_DIALOG,
  USER_DEACTIVATION_REASONS_RELOAD,
  USER_DEACTIVATION_REASONS_GET_ALL,
  USER_DEACTIVATION_REASONS_GET_ALL_LIST,
} from "../../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  saveUserDeactivationPageNum: 1,
  pageSize: 10,
  form: {
    id: null,
    nameAR: "",
    nameEN: "",
  },
  userDeactivationData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  userDeactivationDataList: [],
  getById: {},
};

function userDeactivationReducer(userDeactivation = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_DEACTIVATION_REASONS_GET_ALL:
      userDeactivation.userDeactivationData.data = payload.data;
      userDeactivation.userDeactivationData.total = payload.pageCount;
      userDeactivation.userDeactivationData.rowCount = payload.rowCount;
      return userDeactivation;

    case USER_DEACTIVATION_REASONS_GET_ALL_LIST:
      userDeactivation.userDeactivationDataList = payload;
      return userDeactivation;

    case USER_DEACTIVATION_REASONS_GET_BY_ID:
      userDeactivation.form.id = payload.id;
      userDeactivation.form.nameEN = payload.nameEN;
      userDeactivation.form.nameAR = payload.nameAR;
      return userDeactivation;

    case USER_DEACTIVATION_REASONS_RESET_FORM:
      userDeactivation.form.id = null;
      userDeactivation.form.nameEN = "";
      userDeactivation.form.nameAR = "";
      return userDeactivation;

    case USER_DEACTIVATION_REASONS_PAGE_NUMBER:
      return { ...userDeactivation, saveUserDeactivationPageNum: payload };

    case USER_DEACTIVATION_REASONS_OPEN_DIALOG:
      return { ...userDeactivation, openDialog: !userDeactivation.openDialog };

    case USER_DEACTIVATION_REASONS_RELOAD:
      return { ...userDeactivation, reload: !userDeactivation.reload };

    default:
      return userDeactivation;
  }
}

export default userDeactivationReducer;
