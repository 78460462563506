import {
  ITEM_OPTIONS_RESET_FORM,
  ITEM_OPTIONS_GET_BY_ID,
  ITEM_OPTIONS_PAGE_NUMBER,
  ITEM_OPTIONS_OPEN_DIALOG,
  ITEM_OPTIONS_RELOAD,
  ITEM_OPTIONS_GET_ALL_LIST,
  ITEM_OPTIONS_GET_ALL,
  ITEM_OPTIONS_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  form: {
    id: null,
    nameEn: "",
    nameAr: "",
  },
  itemOptionsData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  searchBody: {
    pageSize: 15,
    name: "",
  },
  itemOptionsDataList: [],
  getById: {},
};

function itemOptionsReducer(itemOptions = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ITEM_OPTIONS_GET_ALL:
      itemOptions.itemOptionsData.data = payload.data;
      itemOptions.itemOptionsData.total = payload.pageCount;
      itemOptions.itemOptionsData.rowCount = payload.rowCount;
      return itemOptions;

    case ITEM_OPTIONS_GET_ALL_LIST:
      itemOptions.itemOptionsDataList = payload;
      return itemOptions;

    case ITEM_OPTIONS_GET_BY_ID:
      itemOptions.form.id = payload.id;
      itemOptions.form.nameEn = payload.nameEn;
      itemOptions.form.nameAr = payload.nameAr;
      return itemOptions;

    case ITEM_OPTIONS_RESET_FORM:
      itemOptions.form.id = null;
      itemOptions.form.nameEn = "";
      itemOptions.form.nameAr = "";
      return itemOptions;

    case ITEM_OPTIONS_PAGE_NUMBER:
      return { ...itemOptions, pageNo: payload };

    case ITEM_OPTIONS_OPEN_DIALOG:
      return {
        ...itemOptions,
        openDialog: !itemOptions.openDialog,
      };

    case ITEM_OPTIONS_SEARCH_BODY:
      return { ...itemOptions, searchBody: payload };

    case ITEM_OPTIONS_RELOAD:
      return { ...itemOptions, reload: !itemOptions.reload };

    default:
      return itemOptions;
  }
}

export default itemOptionsReducer;
