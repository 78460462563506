import {
  ORDER_CANCEL_REASONS_RESET_FORM,
  ORDER_CANCEL_REASONS_GET_BY_ID,
  ORDER_CANCEL_REASONS_PAGE_NUMBER,
  ORDER_CANCEL_REASONS_OPEN_DIALOG,
  ORDER_CANCEL_REASONS_RELOAD,
  ORDER_CANCEL_REASONS_GET_ALL,
  ORDER_CANCEL_REASONS_GET_ALL_LIST,
} from "../../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  saveOrderCancelPageNum: 1,
  pageSize: 10,
  form: {
    id: null,
    nameAR: "",
    nameEN: "",
  },
  orderCancelReasonData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  orderCancelReasonDataList: [],
  getById: {},
};

function orderCancelReasonReducer(orderCancelReason = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ORDER_CANCEL_REASONS_GET_ALL:
      orderCancelReason.orderCancelReasonData.data = payload.data;
      orderCancelReason.orderCancelReasonData.total = payload.pageCount;
      orderCancelReason.orderCancelReasonData.rowCount = payload.rowCount;
      return orderCancelReason;

    case ORDER_CANCEL_REASONS_GET_ALL_LIST:
      orderCancelReason.orderCancelReasonDataList = payload;
      return orderCancelReason;

    case ORDER_CANCEL_REASONS_GET_BY_ID:
      orderCancelReason.form.id = payload.id;
      orderCancelReason.form.nameEN = payload.nameEN;
      orderCancelReason.form.nameAR = payload.nameAR;
      return orderCancelReason;

    case ORDER_CANCEL_REASONS_RESET_FORM:
      orderCancelReason.form.id = null;
      orderCancelReason.form.nameEN = "";
      orderCancelReason.form.nameAR = "";
      return orderCancelReason;

    case ORDER_CANCEL_REASONS_PAGE_NUMBER:
      return { ...orderCancelReason, saveOrderCancelPageNum: payload };

    case ORDER_CANCEL_REASONS_OPEN_DIALOG:
      return {
        ...orderCancelReason,
        openDialog: !orderCancelReason.openDialog,
      };

    case ORDER_CANCEL_REASONS_RELOAD:
      return { ...orderCancelReason, reload: !orderCancelReason.reload };

    default:
      return orderCancelReason;
  }
}

export default orderCancelReasonReducer;
