import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import jwt from "jwt-decode";
import { Box, Container, Grid, Slide, Typography } from "@mui/material";
import CustomizedSnackbars from "src/components/Snackbar";
import LoginIcon from "@mui/icons-material/Login";
import cookie from "src/utils/cookie";
import CustomText from "src/components/CustomText";
import { useDispatch } from "react-redux";
import { getIp, validateStaffAuth, loginStaffAuth } from "../actions/auth";
import Footer from "src/components/Footer";
import { LoadingButton } from "@mui/lab";

const Login = () => {
  const dispatch = useDispatch();
  const [ip, setIp] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    type: "error",
    message: "",
  });

  useEffect(() => {
    dispatch(getIp()).then((res) => {
      const regex = /IP:\s*(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/;
      const match = res.match(regex);
      setIp(match[1]);
    });
    localStorage.removeItem("decodedIems");
    cookie.clear("token");
  }, [dispatch]);

  const submitLogin = (e) => {
    e.preventDefault();
    loginAction();
  };

  const submitConfirmationLogin = (e) => {
    e.preventDefault();
    loginConfirmationAction();
  };
  const errorAlert = (msg) =>
    setAlert({ open: true, message: msg, type: "error" });
  const successAlert = (msg) =>
    setAlert({ open: true, message: msg, type: "success" });
  const warningAlert = (msg) =>
    setAlert({ open: true, message: msg, type: "warning" });

  const loginAction = () => {
    if (!email || !password)
      warningAlert("PhoneNumber and Password are required.");
    else {
      setLoadingLogin(true);
      dispatch(
        loginStaffAuth({
          email: email?.toLocaleLowerCase(),
          password: password,
          host: "web",
          ip: ip,
        })
      )
        .then((token) => {
          if (process.env.REACT_APP_ENV === "QA") {
            const decodedToken = jwt(token);
            if (decodedToken.Role === "Customer")
              errorAlert("Sorry, you do not have the right role");
            else {
              cookie.set("token", token);
              localStorage.setItem(
                "viewType",
                decodedToken.ServiceType === "Vertical" ? "gifts" : "cross"
              );
              localStorage.setItem("decodedIems", JSON.stringify(decodedToken));
              successAlert("Login successfully");
              window.location.href = "/app/welcome_message";
            }
            setLoadingConfirmation(false);
          } else {
            handleNext();
          }
          setLoadingLogin(false);
        })
        .catch((err) => {
          setLoadingLogin(false);
          if (err.response) {
            if (err.response.status === 401) errorAlert("Token expire");
            //un-authorized ,Logout
            else if (err.response.status === 400)
              errorAlert(err.response.data.message);
          } else errorAlert("Network error");
        });
    }
  };

  function loginConfirmationAction() {
    if (!code) warningAlert("Confirmation Code is required.");
    else {
      setLoadingConfirmation(true);
      dispatch(
        validateStaffAuth({
          email: email[0] === "0" ? email.substring(1) : email,
          password: password,
          code: code,
          host: "web",
          ip: ip,
        })
      )
        .then((token) => {
          const decodedToken = jwt(token);
          if (decodedToken.Role === "Customer")
            errorAlert("Sorry, you do not have the right role");
          else {
            cookie.set("token", token);
            localStorage.setItem(
              "viewType",
              decodedToken.ServiceType === "Vertical" ? "gifts" : "cross"
            );
            localStorage.setItem("decodedIems", JSON.stringify(decodedToken));
            successAlert("Login successfully");
            window.location.href = "/app/welcome_message";
          }
          setLoadingConfirmation(false);
        })
        .catch((err) => {
          setLoadingConfirmation(false);
          if (err.response) {
            if (err.response.status === 401) errorAlert("Token expire");
            //un-authorized ,Logout
            else if (err.response.status === 400)
              errorAlert(err.response.data.message);
          } else errorAlert("Network error");
        });
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const steps = [
    {
      content: (
        <form onSubmit={submitLogin}>
          <Box sx={{ mb: 3 }}>
            <Typography color="textPrimary" variant="h2">
              Login
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Login using your <b>Email</b> and <b>Password</b>
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
          <CustomText
            fullWidth
            size="large"
            label="Email"
            name="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            // type="number"
            value={email}
          />
          <CustomText
            fullWidth
            size="large"
            label="Password"
            name="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type="password"
            value={password}
          />
          <Box sx={{ py: 2 }}>
            <LoadingButton
              type="submit"
              color="primary"
              fullWidth
              size="large"
              variant="contained"
              loading={loadingLogin}
              startIcon={<LoginIcon />}
            >
              Login now
            </LoadingButton>
          </Box>
        </form>
      ),
      buttonTitle: "Login Now",
    },
    {
      content: (
        <Slide direction="left" in={activeStep === 1}>
          <form onSubmit={(e) => submitConfirmationLogin(e)}>
            <Box sx={{ mb: 3 }}>
              <Typography color="textPrimary" variant="h2">
                Confirmation
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Confirmation <b>code</b> has been sent to your email
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <CustomText
              fullWidth
              size="large"
              label="Confirmation Code"
              name="confirmationCode"
              onChange={(e) => {
                setCode(e.target.value);
              }}
              type="number"
              value={code}
              autoFocus
            />
            <Box sx={{ py: 2 }}>
              <LoadingButton
                type="submit"
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                loading={loadingConfirmation}
                startIcon={<LoginIcon />}
              >
                Confirm
              </LoadingButton>
            </Box>
          </form>
        </Slide>
      ),
      buttonTitle: "Confirm",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Login | Orderii</title>
      </Helmet>
      <CustomizedSnackbars
        open={alert.open}
        type={alert.type}
        message={alert.message}
        onClose={() =>
          setAlert((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100%"
      >
        <Grid item xs={12}>
          <Container maxWidth="sm">
            <Box>
              <Box>{steps[activeStep].content}</Box>
            </Box>
            <Box
              width={"100%"}
              component="img"
              src="/static/images/logo/logo_orderii_dual.png"
            />
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth="md" style={{ marginTop: 50 }}>
            <Footer />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
