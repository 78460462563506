import {
  TextField,
  Grid,
  Stack,
  Button,
  Box,
  Tooltip,
  FormControlLabel,
  Switch,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  createGiftCategory,
  openGiftsCategoryDialog,
  resetGiftsCategoryForm,
  updateGiftsCategory,
} from "src/actions/gifts/giftCategory";
import { getAllAddresses } from "src/actions/addresses";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IoCloseCircle } from "react-icons/io5";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { uploadAttachments } from "src/actions/uploadAttachments";
import { category_types } from "src/utils/common";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function Item({ item, control, index, remove }) {
  const {
    fields,
    append,
    remove: removeValue,
  } = useFieldArray({
    control,
    name: `filterCollection[${index}].values`,
  });

  return (
    <Card
      key={item.id}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        padding: "15px",
        margin: "15px 0",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", my: 2 }}
      >
        <Controller
          control={control}
          name={`filterCollection[${index}].nameEn`}
          defaultValue={item.nameEn}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Filter collection (English)"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginY: 2 }}>
                    <IconButton
                      variant="contained"
                      type="button"
                      color="error"
                      onClick={() => remove(index)}
                    >
                      <IoCloseCircle />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={`filterCollection[${index}].nameAr`}
          defaultValue={item.nameAr}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="Filter collection (Arabic)"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginY: 2 }}>
                    <IconButton
                      variant="contained"
                      type="button"
                      color="error"
                      onClick={() => remove(index)}
                    >
                      <IoCloseCircle />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {fields.map((valueItem, i) => (
          <Grid item xs={12} md={12} key={valueItem.id}>
            <Stack direction="row" spacing={2}>
              <Controller
                control={control}
                name={`filterCollection[${index}].values[${i}].nameEn`}
                defaultValue={valueItem.english}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Value (English)"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ marginY: 2 }}>
                          <IconButton
                            variant="contained"
                            type="button"
                            color="error"
                            onClick={() => removeValue(i)}
                          >
                            <IoCloseCircle />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`filterCollection[${index}].values[${i}].nameAr`}
                defaultValue={valueItem.arabic}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    label="Value (Arabic)"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ marginY: 2 }}>
                          <IconButton
                            variant="contained"
                            type="button"
                            color="error"
                            onClick={() => removeValue(i)}
                          >
                            <IoCloseCircle />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Button
        sx={{ my: 2, width: "20%" }}
        variant="contained"
        type="button"
        onClick={() => append({ nameAr: "", nameEn: "" })}
      >
        Add Value
      </Button>
    </Card>
  );
}

const GiftCategoryForm = () => {
  const dispatch = useDispatch();

  const giftItemCategory = useSelector((state) => state.giftItemCategory);
  const loading = useSelector((state) => state.giftItemCategory.reload);
  const searchBody = useSelector((state) => state.giftItemCategory.searchBody);
  const pageNo = useSelector(
    (state) => state.giftItemCategory.saveGiftItemCategoryPageNum
  );
  const _Addresses = useSelector((state) => state.addresses);

  useEffect(() => {
    dispatch(getAllAddresses());
  }, [dispatch]);

  const {
    handleSubmit,
    control,
    reset,
    // formState: { isDirty },
    setValue,
    watch,
  } = useForm({
    defaultValues: giftItemCategory.form,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "filterCollection",
  });

  const [attachmentsFiles, setAttachmentsFiles] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (attachmentsFiles.length > 0) {
      setFiles(attachmentsFiles.map((files) => files.file));
      setValue("attachmentId", attachmentsFiles.map((files) => files.file)[0], {
        shouldDirty: true,
      });
    } else {
      setValue("attachmentId", watch("attachmentId"), {
        shouldDirty: true,
      });
    }
  }, [attachmentsFiles, setValue, watch]);

  const isAdd = giftItemCategory.form.id ? false : true;

  const handleDialog = () => {
    dispatch(openGiftsCategoryDialog());
  };

  useEffect(() => {
    reset({ ...giftItemCategory.form });
  }, [reset, giftItemCategory.form]);

  useEffect(() => {
    return () => {
      dispatch(resetGiftsCategoryForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    if (
      rightChecked.length > 0 ||
      leftChecked.length > 0 ||
      right.length === 0
    ) {
      dispatch(
        showErrorSnackbar(
          "You have selected some provinces and didn't move them to the right side, please move them to the right side or remove them from the left side"
        )
      );
    } else {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("type", 3);
      if (isAdd) {
        dispatch(uploadAttachments(formData))
          .then((res) => {
            dispatch(
              createGiftCategory(
                {
                  isActive: data.isActive,
                  sequence: data.sequence,
                  nameAr: data.nameAr,
                  nameEn: data.nameEn,
                  attachmentId: res.data.id,
                  provinces: right,
                  filterCollection: data.filterCollection,
                  type: data.type,
                },
                {
                  pageNo: pageNo + 1,
                  pageSize: searchBody.pageSize,
                  name: searchBody.name,
                }
              )
            );
          })
          .catch((err) => {});
      } else {
        if (files[0]) {
          dispatch(uploadAttachments(formData))
            .then((res) => {
              dispatch(
                updateGiftsCategory(
                  data.id,
                  {
                    isActive: data.isActive,
                    sequence: data.sequence,
                    nameAr: data.nameAr,
                    nameEn: data.nameEn,
                    attachmentId: res.data.id,
                    provinces: right,
                    filterCollection: data.filterCollection,
                    type: data.type,
                  },
                  {
                    pageNo: pageNo + 1,
                    pageSize: searchBody.pageSize,
                    name: searchBody.name,
                  }
                )
              );
            })
            .catch((err) => {});
        } else {
          dispatch(
            updateGiftsCategory(
              data.id,
              {
                isActive: data.isActive,
                sequence: data.sequence,
                nameAr: data.nameAr,
                nameEn: data.nameEn,
                attachmentId: data.attachmentId,
                provinces: right,
                filterCollection: data.filterCollection,
                type: data.type,
              },
              {
                pageNo: pageNo + 1,
                pageSize: searchBody.pageSize,
                name: searchBody.name,
              }
            )
          );
        }
      }
    }
  };

  // ------------- Province  ---------------- //
  const [leftData, setLeftData] = useState([]);
  const [rightData, setRightData] = useState([]);
  const [right, setRight] = useState([]);
  const [left, setLeft] = useState([]);

  useEffect(() => {
    const rightProvinces = giftItemCategory.form?.provinces;
    setRight(rightProvinces?.map((province) => province.id));
    setRightData(rightProvinces);
  }, [giftItemCategory.form?.provinces]);

  useEffect(() => {
    const leftProvinces = _Addresses?.filter(
      (province) => !right.includes(province.id)
    );
    setLeft(leftProvinces?.map((province) => province.id));
    setLeftData(leftProvinces);
  }, [_Addresses, right]);

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    const newLeft = not(left, leftChecked);
    setRight(newRight);
    setLeft(newLeft);
    setChecked(not(checked, leftChecked));
    setRightData(
      newRight.map((id) => _Addresses.find((province) => province.id === id))
    );
    setLeftData(
      newLeft.map((id) => _Addresses.find((province) => province.id === id))
    );
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked);
    const newRight = not(right, rightChecked);
    setLeft(newLeft);
    setRight(newRight);
    setChecked(not(checked, rightChecked));
    setLeftData(
      newLeft.map((id) => _Addresses.find((province) => province.id === id))
    );
    setRightData(
      newRight.map((id) => _Addresses.find((province) => province.id === id))
    );
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, width: "100%" }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: "920px",
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {leftData?.map((province) => {
          const labelId = `transfer-list-all-item-${province.id}-label`;

          return (
            <ListItem
              key={province.id}
              role="listitem"
              onClick={handleToggle(province.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(province.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${province.nameUS} - ${province.nameAR}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
  const selectedCustomList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, width: "100%" }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            disabled={items.length === 0}
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${items.length} Cities`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: "920px",
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {rightData?.map((province, index) => {
          const labelId = `transfer-list-all-item-${province.id}-label`;

          return (
            <ListItem
              key={province.id}
              role="listitem"
              onClick={handleToggle(province.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(province.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${province.nameUS} - ${province.nameAR}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Dialog
      open={giftItemCategory.openDialog}
      keepMounted
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogTitle>
        <Typography color="primary">
          {isAdd ? "Create Product Category" : "Update Product Category"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            py={2}
          >
            <Grid item xs={12} md={6}>
              <Controller
                name="nameEn"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    inputProps={{
                      minLength: 4,
                    }}
                    size="small"
                    label="Name English"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="nameAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    inputProps={{
                      minLength: 4,
                    }}
                    size="small"
                    label="Name Arabic"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="sequence"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Sequence"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={category_types}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Type" />
                    )}
                    onChange={(event, value) => {
                      setValue("type", value ? value.id : null, {
                        shouldDirty: true,
                      });
                    }}
                    value={
                      category_types.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {fields.map((item, index) => (
                <Item
                  key={item.id}
                  item={item}
                  control={control}
                  index={index}
                  remove={remove}
                />
              ))}
              <Button
                variant="contained"
                type="button"
                onClick={() => append({ nameAr: "", nameEn: "", values: [""] })}
              >
                Add Filter Collection
              </Button>
            </Grid>
            <Grid item xs={5}>
              {customList("All Provinces", left)}
            </Grid>
            <Grid item xs={2}>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  <AiOutlineArrowRight />
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  {left.length <= 0 ? (
                    <DeleteForeverIcon />
                  ) : (
                    <AiOutlineArrowLeft />
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              {selectedCustomList("Selected Provinces", right)}
            </Grid>
            {!isAdd ? (
              <Grid
                item
                xs={12}
                md={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  component="img"
                  src={giftItemCategory.form.imageUrl}
                  width={300}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={12}>
              <Controller
                name="attachmentId"
                control={control}
                render={({ field }) => (
                  <FilePond
                    required={watch("attachmentId") === "" ? true : false}
                    files={attachmentsFiles}
                    onupdatefiles={setAttachmentsFiles}
                    maxFiles={10}
                    name="file"
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    credits={false}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Controller
          name="isActive"
          control={control}
          render={({ field }) => (
            <Tooltip title="FALSE | TRUE" aria-label="active_deactivate">
              <FormControlLabel
                control={
                  <Switch
                    checked={field.value}
                    onChange={(e) => {
                      setValue("isActive", e.target.checked, {
                        shouldDirty: true,
                      });
                    }}
                  />
                }
                label="Is Active"
              />
            </Tooltip>
          )}
        />
        <LoadingButton
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          color="primary"
          loading={loading}
          // disabled={!isDirty}
          sx={{ width: "25%", my: 2 }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default GiftCategoryForm;
