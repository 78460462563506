import * as React from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  InputAdornment,
  Grid,
} from "@mui/material";
import moment from "moment";
import { updateLockerNumber } from "src/actions/orders";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Icon } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import { useState, useEffect } from "react";
import { RiVipFill } from "react-icons/ri";
import { RiVipCrownFill } from "react-icons/ri";
import { useNavigate } from "react-router";
import { GiLockers } from "react-icons/gi";
import CustomText from "src/components/CustomText";
import { CustomDialog } from "src/components/CustomDialog";
import OrderStatusMenu from "../../menu/OrderStatus";
import TimelineDialog from "../../timeline/OrderTimeline";
import GenerateInvoice from "../../generate/generateInvoice";
import CancelDialog from "../../CancelDialog/CancelDialog";
import RolePermission from "src/components/RolePermission";
import { FaShoppingBag } from "react-icons/fa";
import GenerateLabel from "src/pages/cross/orders/generate/generateLabel";
import { MdOutlineStarRate } from "react-icons/md";
import ReactStars from "react-rating-stars-component";
import { openDialog } from "src/actions/orderAddtionalFees";

export default function Details(props) {
  const order = useSelector((state) => state.orders.orderDetails);
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const navigate = useNavigate();

  const [openLabelDialog, setOpenLabelDialog] = useState(false);
  const [openTimelineDialog, setOpenTimelineDialog] = useState(false);
  const [isOrder, setIsOrder] = useState(null);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [openCancelReasonsDialog, setOpenCancelReasonsDialog] = useState(false);
  const [lockerNumber, setLockerNumber] = useState("");
  const [openRatingDialog, setOpenRatingDialog] = useState(false);
  const [ratingData, setRatingData] = useState({});

  useEffect(() => setLockerNumber(order.lockerNumber ?? ""), [order]);

  const orderId = window.location.pathname.replace("/app/order/", "");

  const dispatch = useDispatch();

  // function handleUpdateDelivery(data) {
  //   dispatch(updateDelivery(data, data.id));
  // }

  function handleLockerNumber() {
    dispatch(updateLockerNumber(orderId, lockerNumber));
  }

  return (
    <Box>
      <CustomDialog
        isOpen={openInvoiceDialog}
        handleClose={() => setOpenInvoiceDialog(false)}
        title={"INVOICE"}
        maxWidth="md"
      >
        <GenerateInvoice data={order} />
      </CustomDialog>

      <CustomDialog
        isOpen={openLabelDialog}
        handleClose={() => setOpenLabelDialog(false)}
        title={"Label"}
        maxWidth="md"
      >
        <GenerateLabel data={order} />
      </CustomDialog>

      <CancelDialog
        open={openCancelReasonsDialog}
        handleClose={() => setOpenCancelReasonsDialog(false)}
        type={isOrder ? "order" : "item"}
        id={orderId}
        orderId={orderId}
        onError={(msg) => dispatch(showErrorSnackbar(msg))}
        onSuccess={(msg) => {
          dispatch(showSuccessSnackbar(msg));
          props.refresh();
        }}
      />
      <TimelineDialog
        open={openTimelineDialog}
        handleClose={() => setOpenTimelineDialog(false)}
        orderId={order.id}
      />
      <CustomDialog
        isOpen={openRatingDialog}
        handleClose={() => setOpenRatingDialog(false)}
        title="Order Rating"
        maxWidth="md"
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h3">Average:</Typography>
          <ReactStars
            edit={false}
            value={ratingData?.ratingAverage}
            size={40}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          {ratingData?.rates?.map((data, index) => (
            <Grid item xs={12} key={index}>
              <Typography variant="h4">
                {data?.ratingQuestion?.questionEn}
              </Typography>
              <ReactStars edit={false} value={data.rate} size={32} />
              {index !== ratingData?.rates?.length - 1 && <Divider />}
            </Grid>
          ))}
        </Grid>
      </CustomDialog>
      {order.items.length !== 0 ? (
        <Stack direction="column">
          <Card sx={{ padding: 2 }}>
            <Box>
              <Stack
                sx={{ justifyContent: "space-between", alignItems: "center" }}
                direction={"row"}
                spacing={1}
              >
                <OrderStatusMenu
                  orderId={order.id}
                  status={order.status}
                  onSuccess={(e) => props.refresh()}
                  onCancel={() => {
                    setIsOrder(true);
                    setOpenCancelReasonsDialog(true);
                  }}
                />
                {order.isVipShipped && (
                  <Stack direction="column" spacing={-0.8}>
                    <RiVipCrownFill size={30} color="#D99B12" />
                    <RiVipFill size={30} color="#D99B12" />
                  </Stack>
                )}
              </Stack>
              <Typography variant="h4">{order.number ?? ""}</Typography>
              <Typography variant="h4">
                Total amount: {order.totalAmount ?? 0}$
              </Typography>
              <Typography variant="h5">
                Subtotal: {order.subTotal ?? 0}$
              </Typography>
              <Typography variant="h5">
                IQ customs: {order.totalIQCustoms ?? 0}$
              </Typography>
              <Typography variant="h5">
                Orderii Fee: {order.orderiiFee ?? 0}$
              </Typography>
              {order.hasUSItem && (
                <Typography variant="h5">
                  US shipping: {order.totalUSShipping ?? 0}$
                </Typography>
              )}
              {order.hasTRItem && (
                <Typography variant="h5">
                  TR shipping: {order.totalTRShipping ?? 0}$
                </Typography>
              )}
              {order.hasAGItem && (
                <Typography variant="h5">
                  AG shipping: {order.totalAGShipping ?? 0}$
                </Typography>
              )}
              {order.hasEUItem && (
                <Typography variant="h5">
                  EU shipping: {order.totalEUShiiping ?? 0}$
                </Typography>
              )}
              {order.hasWSMItem && (
                <Typography variant="h5">
                  WSM shipping: {order.totalWSMShipping ?? 0}$
                </Typography>
              )}
              <Stack direction={"row"} spacing={1}>
                <Typography variant="h5">To be delivered: </Typography>
                <Stack direction={"row"} spacing={1}>
                  {order.toBeDelivered ? (
                    <>
                      <Icon color="success">check_circle</Icon>
                      <Typography color="success">
                        {order.deliveryCost}$
                      </Typography>
                    </>
                  ) : (
                    <Icon color="error">cancel</Icon>
                  )}
                </Stack>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography variant="h5">Insurance: </Typography>
                <Stack direction={"row"} spacing={1}>
                  {order.isInsured ? (
                    <>
                      <Icon color="success">check_circle</Icon>
                      <Typography color="success">
                        {order.insuranceFee}$
                      </Typography>
                    </>
                  ) : (
                    <Icon color="error">cancel</Icon>
                  )}
                </Stack>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography variant="h5">VIP Shipping: </Typography>
                <Stack direction={"column"} spacing={1}>
                  {order.isVipShipped ? (
                    <>
                      <Icon color="success">check_circle</Icon>
                    </>
                  ) : (
                    <Icon color="error">cancel</Icon>
                  )}
                </Stack>
              </Stack>
              {order.isVipShipped ? (
                <Box>
                  {order.hasUSItem && (
                    <Typography variant="h5" color="success">
                      Total Vip US Tax: {order.totalVipUSTax}$
                    </Typography>
                  )}
                  {order.hasTRItem && (
                    <Typography variant="h5" color="success">
                      Total Vip TR Tax: {order.totalVipTRTax}$
                    </Typography>
                  )}
                  {order.hasAGItem && (
                    <Typography variant="h5" color="success">
                      Total Vip AG Tax: {order.totalVipAGTax}$
                    </Typography>
                  )}
                  {order.hasEUItem && (
                    <Typography variant="h5" color="success">
                      Total Vip EU Tax: {order.totalVipEUTax}$
                    </Typography>
                  )}
                  {order.hasWSMItem && (
                    <Typography variant="h5" color="success">
                      Total Vip WSM Tax: {order.totalVipWSMTax}$
                    </Typography>
                  )}
                </Box>
              ) : null}
              {order.promoCodeId && (
                <Typography variant="h5">
                  Promo Code: {order.promoCodeName ?? ""}
                </Typography>
              )}
              {order.referralDiscount && (
                <Stack direction="row" spacing={1}>
                  <Typography variant="h5">
                    Referral Code: {order.referralCode}
                  </Typography>
                  <Icon color="success">check_circle</Icon>
                </Stack>
              )}
              <Typography variant="h5">
                Total discount: {order.totalDiscount ?? "0"}$
              </Typography>
              {order.totalCashBack ? (
                <Typography variant="h5">
                  Total CashBack: {order.totalCashBack ?? "0"}$
                </Typography>
              ) : null}
              {/* <RolePermission permission="22-07">
                <Typography variant="h5">
                  Delivery to home!
                  <Tooltip
                    title="(Deactive) | (Active)"
                    aria-label="active_deactive"
                  >
                    <Switch
                      checked={order.toBeDelivered}
                      onChange={(e) => {
                        handleUpdateDelivery({
                          id: order.id,
                          tobeDelivered: e.target.checked,
                        });
                      }}
                    />
                  </Tooltip>
                </Typography>
              </RolePermission> */}
              <Divider sx={{ margin: "5px 0" }}></Divider>
              <Typography variant="h5">
                Total Amount At Creation Date:{" "}
                <strong>{order.totalAmountAtCreationDate ?? "0"}$</strong>
              </Typography>
              <Divider sx={{ margin: "5px 0" }}></Divider>
              <RolePermission permission="22-14">
                <Box>
                  <CustomText
                    fullWidth
                    label="Locker"
                    name="lockerNumber"
                    value={lockerNumber}
                    onChange={(e) => {
                      setLockerNumber(e.target.value);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleLockerNumber() : null
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ marginY: 3 }}>
                          <GiLockers size={25} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" sx={{ marginY: 2 }}>
                          <Button
                            variant="contained"
                            onClick={handleLockerNumber}
                          >
                            Update
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Divider sx={{ margin: 1 }}></Divider>
                </Box>
              </RolePermission>
            </Box>
            <Stack spacing={1}>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Customer name">
                  <Icon>account_circle</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {order.customer ? order.customer.fullName : ""}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Customer phone number">
                  <Icon>call</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {order.customer ? order.customer.phoneNumber : ""}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Customer address">
                  <Icon>pin_drop</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {order.customer ? order.customer.address : ""}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Reviewer name">
                  <Icon>assignment_ind</Icon>
                </Tooltip>
                <Typography variant="body1">{order.reviewer}</Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Tooltip title="Order date">
                  <Icon>today</Icon>
                </Tooltip>
                <Typography variant="body1">
                  {moment(order.createdAt).format("yyy-MM-DD / hh:mm a")}
                </Typography>
              </Stack>
              <Divider></Divider>

              <Stack
                direction={"row"}
                spacing={1}
                sx={{ justifyContent: "center" }}
              >
                <RolePermission permission="58-01">
                  <Tooltip
                    title="Order Additional Fees"
                    onClick={(e) => {
                      dispatch(openDialog("global"));
                    }}
                  >
                    <IconButton>
                      <Icon color="success">attach_money</Icon>
                    </IconButton>
                  </Tooltip>
                </RolePermission>
                {_UserInfo.roles.includes("PrintLabelAndInvoice") ? (
                  <>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "center" }}
                    >
                      <Tooltip
                        title="Print Invoice"
                        onClick={() => setOpenInvoiceDialog(true)}
                      >
                        <IconButton>
                          <LocalPrintshopIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Print Label"
                        onClick={() => setOpenLabelDialog(true)}
                      >
                        <IconButton>
                          <ReceiptIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </>
                ) : null}
                <RolePermission permission="22-03">
                  <Tooltip
                    title="Timeline"
                    onClick={() => setOpenTimelineDialog(true)}
                  >
                    <IconButton>
                      <Icon>show_chart</Icon>
                    </IconButton>
                  </Tooltip>
                </RolePermission>
                <RolePermission permission="34-12">
                  <Tooltip
                    title="Go to wallet"
                    onClick={() => {
                      navigate(
                        `/app/wallet/${
                          order.customer ? order.customer.id : null
                        }`
                      );
                    }}
                  >
                    <IconButton>
                      <Icon>wallet</Icon>
                    </IconButton>
                  </Tooltip>
                </RolePermission>
                <RolePermission permission="22-04">
                  <Tooltip
                    title="User Orders"
                    onClick={() => {
                      navigate(`/app/user_orders/${order.customer.id}`);
                    }}
                  >
                    <IconButton>
                      <FaShoppingBag size={20} />
                    </IconButton>
                  </Tooltip>
                </RolePermission>
                {order?.orderRating?.length > 0 ? (
                  <Tooltip
                    title="Order Rating"
                    onClick={() => {
                      setOpenRatingDialog(true);
                      setRatingData(order?.orderRating[0]);
                    }}
                  >
                    <IconButton>
                      <MdOutlineStarRate />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Stack>
            </Stack>
          </Card>
        </Stack>
      ) : null}
    </Box>
  );
}
