import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Button,
  Autocomplete,
  LinearProgress,
  Stack,
  Grid,
  CardHeader,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAddresses,
  createCity,
  updateCity,
  deleteCity,
} from "src/actions/addresses";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Close from "@mui/icons-material/Close";
import { CustomDialog } from "src/components/CustomDialog";
import CustomText from "src/components/CustomText";
import CustomSpinner from "src/components/CustomSpinner";
import { Helmet } from "react-helmet";
import { IoLocationSharp } from "react-icons/io5";

export default function AddressesList() {
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isReload, setIsReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCityDialog, setOpenCityDialog] = useState(false);

  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedProvinceId, setSelectedProvinceId] = useState("");
  const [searchCity, setSearchCity] = useState("");

  const [cityData, setCityData] = useState({
    id: null,
    provinceId: null,
    nameAR: "",
    nameUS: "",
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const addresses = useSelector((state) => state.addresses);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getAllAddresses()).then((x) => {
      setLoading(false);
    });
  }, [isReload]);

  function clearTexts() {
    setCityData({
      ...cityData,
      id: null,
      provinceId: null,
      nameAR: "",
      nameUS: "",
    });
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleCityDialogClose = () => {
    setOpenCityDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCityData({ ...cityData, [name]: value });
  };

  const ListCities = (props) => {
    return (
      <Autocomplete
        id={props.cities.id}
        options={props.cities}
        getOptionLabel={(option) => `${option.nameAR} , ${option.nameUS}`}
        sx={{ width: 300 }}
        fullWidth
        size="small"
        renderInput={(params) => <TextField {...params} label="Cities" />}
        onChange={(event, value) => {
          setSelectedProvince(value ?? null);
        }}
        value={selectedProvince}
      />
    );
  };

  function addNewCity() {
    dispatch(createCity(cityData));
  }

  function editCity() {
    dispatch(updateCity(cityData));
  }

  function deleteCityId(id) {
    setLoading(true);
    dispatch(deleteCity(id)).then((x) => {
      setSelectedProvince({
        ...selectedProvince,
        cities: selectedProvince.cities.filter((obj) => obj.id !== id),
      });
      setLoading(false);
      setOpenDeleteDialog(false);
    });
  }

  useEffect(() => {
    if (searchCity == "") {
      addresses.map((city) => {
        city.id == selectedProvinceId && setSelectedProvince(city);
      });
    } else {
      const filteredCities = addresses
        .filter((city) => city.id === selectedProvinceId)[0]
        .cities.filter(
          (city) =>
            city.nameUS.toLowerCase().startsWith(searchCity.toLowerCase()) ||
            city.nameAR.startsWith(searchCity)
        );

      setSelectedProvince({
        ...selectedProvince,
        cities: filteredCities,
      });
    }
    return selectedProvince;
  }, [searchCity]);

  const addressToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteCityId(cityData.id)}
            selectedObject={`This City ${cityData.nameAR}`}
            loading={isReload}
          >
            You will delete the city with name <b>{cityData.nameAR}</b>
          </CustomDialog>
        )}
        <CustomDialog
          isOpen={openCityDialog}
          handleClose={handleCityDialogClose}
          title={isAdd ? "CREATE CITY" : "UPDATE CITY"}
          maxWidth="sm"
          isRequierdActions={true}
          isRequierdAdd={isAdd ? true : false}
          isRequierdEdit={isAdd ? false : true}
          onAdd={addNewCity}
          onEdit={editCity}
          onClear={clearTexts}
          loading={isReload}
        >
          <form noValidate autoComplete="off">
            <Box>
              <CustomText
                label="Arabic name"
                name="nameAR"
                value={cityData.nameAR}
                onChange={handleInputChange}
                style={{ margin: "5px" }}
              />
              <CustomText
                label="English name"
                name="nameUS"
                value={cityData.nameUS}
                onChange={handleInputChange}
                style={{ margin: "5px" }}
              />
            </Box>
          </form>
        </CustomDialog>
        <CustomDialog
          isOpen={openDialog}
          handleClose={handleDialogClose}
          title={"CITIES"}
          maxWidth="md"
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "space-between" }}
          >
            <form noValidate autoComplete="off">
              <Box>
                <Button
                  color="success"
                  variant="contained"
                  startIcon={<AddLocationAltIcon />}
                  onClick={() => {
                    setOpenCityDialog(true);
                    setIsAdd(true);
                    setCityData({
                      ...cityData,
                      provinceId: selectedProvince.id,
                      nameAR: "",
                      nameUS: "",
                    });
                  }}
                >
                  {`Add city to ${
                    selectedProvince ? selectedProvince.nameUS : ""
                  }`}
                </Button>
              </Box>
            </form>
            <Stack direction="row" spacing={2}>
              <TextField
                label="City Filter"
                size="small"
                fullWidth
                value={searchCity}
                onChange={(e) => {
                  setSearchCity(e.target.value);
                  setSelectedProvinceId(selectedProvince.id);
                }}
              />
              <Tooltip title="Clear filter">
                <IconButton
                  onClick={() => {
                    addresses.map((city) => {
                      city.id == selectedProvinceId &&
                        setSelectedProvince(city);
                    });
                    setSearchCity("");
                  }}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Box
            sx={{
              height: {
                md: "calc(100vh - 250px)",
                xs: 1000,
                marginTop: "10px",
              },
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Ar name</TableCell>
                  <TableCell>En name</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProvince
                  ? selectedProvince.cities
                      .slice(0, selectedProvince.cities.length)
                      .map((city) => (
                        <TableRow hover key={city.id}>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell>{city.nameAR}</TableCell>
                          <TableCell>{city.nameUS}</TableCell>
                          <TableCell>
                            <IconButton
                              color="info"
                              aria-label="addressItems"
                              onClick={(e) => {
                                clearTexts();
                                setIsAdd(false);
                                setOpenCityDialog(true);
                                setCityData(
                                  selectedProvince.cities.filter(
                                    (x) => x.id == city.id
                                  )[0]
                                );
                              }}
                            >
                              <Tooltip title="Edit city">
                                <EditLocationAltIcon />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={(e) => {
                                setOpenDeleteDialog(true);
                                setCityData(
                                  selectedProvince.cities.filter(
                                    (x) => x.id == city.id
                                  )[0]
                                );
                              }}
                            >
                              <Tooltip title="Delete city" aria-label="delete">
                                <DeleteForeverIcon />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  : null}
              </TableBody>
            </Table>
          </Box>
        </CustomDialog>
      </Box>
    );
  };
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Helmet>
            <title>Addresses | Orderii</title>
          </Helmet>
          <Box
            sx={{
              backgroundColor: "background.default",
              minHeight: "100%",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography variant="h4" component="div">
                    Addresses
                  </Typography>
                }
                avatar={<IoLocationSharp size={20} />}
              />
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <Card>
            {addressToolbar()}
            <PerfectScrollbar>
              {loading ? <LinearProgress xs={{ marginY: 2 }} /> : null}
              <Box sx={{ height: { md: "calc(100vh - 250px)", xs: 1000 } }}>
                {!addresses ? (
                  <CustomSpinner />
                ) : (
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Id</TableCell>
                        <TableCell>Ar name</TableCell>
                        <TableCell>En name</TableCell>
                        <TableCell>Cities</TableCell>
                        <TableCell>Operations</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addresses.slice(0, limit).map((address) => (
                        <TableRow hover key={address.id}>
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell>{address.id}</TableCell>
                          <TableCell>{address.nameAR}</TableCell>
                          <TableCell>{address.nameUS}</TableCell>
                          <TableCell>
                            {address.cities.length}
                            {/* <ListCities cities={address.cities} /> */}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="info"
                              aria-label="addressItems"
                              onClick={(e) => {
                                setOpenDialog(true);
                                setSelectedProvince(address);
                                setSearchCity("");
                              }}
                            >
                              <Tooltip title="Edit">
                                <EditIcon />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </PerfectScrollbar>
            <TablePagination
              component="div"
              count={addresses ? addresses.length : 0}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[25]}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
