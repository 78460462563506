import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box } from "@mui/system";
import {
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  LinearProgress,
  Button,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@mui/material";
import { useEffect } from "react";
import CustomSpinner from "src/components/CustomSpinner";
import CustomEmpty from "src/components/CustomEmpty";
import { DataGrid } from "@mui/x-data-grid";
import { getCartById, updateCallHistory } from "src/actions/gifts/cart";
import { useState } from "react";
import { CustomDialog } from "src/components/CustomDialog";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { getProductInfo } from "src/actions/gifts/giftItem";
import { FaEye } from "react-icons/fa";
import ProductInfo from "src/pages/gifts/item/from/productInfo";
import ReactCountryFlag from "react-country-flag";
import { country_codes } from "src/utils/common";
import moment from "moment";
import RolePermission from "src/components/RolePermission";

export default function GiftCartTable() {
  const dispatch = useDispatch();

  const giftCart = useSelector((state) => state.giftCart.data);
  const reload = useSelector((state) => state.giftCart.reload);
  const giftItemDialog = useSelector(
    (state) => state.giftItems.openProductInfoDialog
  );
  const [showPanZoom, setShowPanZoom] = useState("");
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openCallHistoryDialog, setOpenCallHistoryDialog] = useState(false);

  const cartId = window.location.pathname.replace("/app/gift_cart/", "");
  // const [refresh, setRefresh] = useState(false);
  // const [editLoading, setEditLoading] = useState(false);
  // const [openEditDialog, setOpenEditDialog] = useState(false);

  useEffect(() => {
    getUserCartById(cartId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getUserCartById(id) {
    dispatch(getCartById(id));
  }

  // const [selectedItem, setSelectedItem] = React.useState({
  //   itemId: null,
  //   weight: "",
  //   weightUnit: "",
  //   dimensions: "",
  //   dimensionsUnit: "",
  //   dangerousGoods: "",
  //   dangerousGoodsUnit: "",
  //   shippingCost: 0,
  //   deliveryDate: "",
  //   quantity: "",
  // });

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   const newValue = value.replace(/\u200E/g, "");
  //   setSelectedItem({ ...selectedItem, [name]: newValue });
  // };

  // function clearTexts() {
  //   setSelectedItem({
  //     ...selectedItem,
  //     weight: "",
  //     weightUnit: "",
  //     dimensions: "",
  //     dimensionsUnit: "",
  //     dangerousGoods: "",
  //     dangerousGoodsUnit: "",
  //     deliveryDate: null,
  //     shippingCost: 0,
  //     quantity: "",
  //   });
  // }

  const handleDialogClose = () => {
    setOpenImageDialog(false);
  };

  const handleOpenPanZoom = (url) => {
    setOpenImageDialog(true);
    setShowPanZoom(url);
  };

  const Item = ({ params }) => {
    return (
      <Stack spacing={0} sx={{ width: "600px" }}>
        <Stack direction="row">
          <Box
            onClick={() =>
              handleOpenPanZoom(
                params?.giftItemOptions?.find(
                  (option) => option.attachments?.url
                )?.attachments?.url || params?.giftItemAttachments[0]?.url
              )
            }
            component="img"
            sx={{
              height: "150px",
              width: "150px",
              objectFit: "contain",
            }}
            alt="itemImage"
            src={
              params?.giftItemOptions?.find((option) => option.attachments?.url)
                ?.attachments?.url || params?.giftItemAttachments[0]?.url
            }
          />
          <Box
            sx={{
              marginLeft: "10px",
              width: "450px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
            }}
          >
            <Tooltip title={params?.nameEn}>
              <Typography
                variant="h5"
                style={{ whiteSpace: "normal", my: 1, fontWeight: "bold" }}
              >
                {params?.nameEn}
              </Typography>
            </Tooltip>
            <Typography color="green" variant="body1" sx={{ my: 0.5 }}>
              {params?.giftItemOptions.map((option, index) => (
                <span key={index}>
                  {option?.options?.nameEn} : {option?.nameEn}{" "}
                </span>
              ))}
            </Typography>
            <Tooltip title={`${params?.descriptionEn}`}>
              <Typography
                variant="body1"
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  whiteSpace: "normal",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Description: </span>
                {`${params?.descriptionEn}`}
              </Typography>
            </Tooltip>
            <Typography variant="h5" color="secondary">
              {params.giftItemCategories?.map(
                (x, i) =>
                  `${x?.nameEn?.toUpperCase()}${
                    i < params.giftItemCategories.length - 1 ? " - " : ""
                  }`
              )}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    );
  };

  // function updateItem() {
  //   setEditLoading(true);
  //   selectedItem.itemId = selectedItem.id;
  //   dispatch(updateCart(selectedItem)).then((res) => {
  //     setOpenEditDialog(false);
  //     setRefresh(!refresh);
  //     setEditLoading(false);
  //   });
  // }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "item",
      headerName: "Item Details",
      flex: 3,
      renderCell: (params) => <Item params={params.row.giftItem} />,
    },
    {
      field: "giftBrand",
      headerName: "Brand",
      flex: 1,
      renderCell: (params) => params.row.giftItem?.giftBrand?.nameEn,
    },
    {
      field: "giftSupplier",
      headerName: "Supplier",
      flex: 1,
      renderCell: (params) => params.row.giftItem?.giftSupplier?.nameEn,
    },
    {
      field: "sellPrice",
      headerName: "Sell Price (IQD)",
      flex: 1,
      renderCell: (params) => params.row.giftItem?.sellPrice?.toLocaleString(),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "totalPrice",
      headerName: "Total Price (IQD)",
      flex: 1,
      renderCell: (params) => params.row.totalPrice?.toLocaleString(),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title="View Product Details"
          onClick={() => {
            dispatch(getProductInfo(params.row?.giftItem?.id));
          }}
        >
          <IconButton>
            <FaEye />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const callHistoryColumns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "userName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => params.row.user?.name,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      renderCell: (params) => params.row.user?.phoneNumber,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) =>
        moment(params.row.createdAt).format("YYYY-MM-DD / HH:mm a"),
    },
  ];

  return (
    <Card>
      {!giftCart ? (
        <CustomSpinner />
      ) : (
        <Box
          sx={{
            padding: 2.5,
          }}
        >
          {giftItemDialog && <ProductInfo />}
          <CustomDialog
            isOpen={openImageDialog}
            handleClose={() => handleDialogClose()}
            title="Images"
            maxWidth="lg"
          >
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                  width: "50%",
                  height: "auto",
                }}
              >
                <ReactPanZoom image={showPanZoom} alt="Image alt text" />
              </div>
            </div>
          </CustomDialog>
          <CustomDialog
            isOpen={openCallHistoryDialog}
            handleClose={() => setOpenCallHistoryDialog(false)}
            title="Call History"
            maxWidth="lg"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" justifyContent="end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(updateCallHistory(cartId));
                  }}
                >
                  Register Call
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: { md: "calc(100vh - 360px)", xs: 1000 } }}>
                  <DataGrid
                    loading={reload}
                    rows={giftCart.callHistory ?? []}
                    columns={callHistoryColumns}
                    rowHeight={50}
                    disableSelectionOnClick
                    pagination
                    paginationMode="client"
                    components={{
                      LoadingOverlay: LinearProgress,
                      NoRowsOverlay: CustomEmpty,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CustomDialog>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction={"row"} spacing={5}>
              <Stack
                direction="column"
                spacing={2}
                sx={{ alignItems: "center" }}
              >
                <Typography variant="h4">Cart Information</Typography>
                <Stack direction="row" spacing={2}>
                  <Stack>
                    <Typography variant="h4">
                      Total amount:{" "}
                      {giftCart?.totalAmountAfterDiscount?.toLocaleString() ??
                        0}{" "}
                      IQD
                    </Typography>
                    {giftCart?.promoCode && (
                      <Typography variant="h5">
                        Promo Code: {giftCart?.promoCode ?? ""}
                      </Typography>
                    )}
                    <Typography variant="h5">
                      Total Discount: {giftCart?.totalDiscount} IQD
                    </Typography>
                    {giftCart?.totalCashback ? (
                      <Typography variant="h5">
                        Total CashBack: {giftCart?.totalCashback} IQD
                      </Typography>
                    ) : null}
                    {giftCart?.neededBalance > 0 ? (
                      <Typography variant="h5">
                        Needed Balance:{" "}
                        {giftCart?.neededBalance?.toLocaleString()} IQD
                      </Typography>
                    ) : null}

                    {giftCart?.favouritesGiftItemCount > 0 ? (
                      <Typography variant="h5">
                        Favorite Count: {giftCart?.favouritesGiftItemCount}
                      </Typography>
                    ) : null}
                  </Stack>
                </Stack>
              </Stack>
              <Divider orientation="vertical" flexItem></Divider>
              <Stack spacing={2}>
                <Typography variant="h4">Customer details</Typography>
                <Box>
                  <Stack direction={"row"} spacing={1} marginLeft={0.5}>
                    <Tooltip title="Customer name">
                      <Icon>account_circle</Icon>
                    </Tooltip>
                    <Typography variant="body1">
                      {giftCart?.user ? giftCart?.user.name : ""}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} spacing={1} marginLeft={0.5}>
                    <Tooltip title="Customer phone number">
                      <Icon>call</Icon>
                    </Tooltip>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <ReactCountryFlag
                        style={{
                          width: "38px",
                          height: "28px",
                          border: "solid 1px #dedede",
                        }}
                        svg
                        countryCode={
                          country_codes.filter(
                            (code) =>
                              code.dial_code === giftCart?.user?.countryCode
                          )[0]?.code
                        }
                      />
                      <Typography variant="body">
                        {giftCart?.user?.phoneNumber}
                      </Typography>
                    </Stack>
                  </Stack>
                  {/* <Stack direction={"row"} spacing={1} marginLeft={0.5}>
                  <Tooltip title="Customer address">
                    <Icon>pin_drop</Icon>
                  </Tooltip>
                  <Typography variant="body1">
                    {giftCart?.user
                      ? `${
                          giftCart?.user?.currentCountry
                            ? giftCart?.user?.currentCountry + ", "
                            : ""
                        }` +
                        giftCart?.user.province?.nameUS +
                        ", " +
                        giftCart?.user.city?.nameUS
                      : ""}
                  </Typography>
                </Stack> */}
                  <Stack direction={"row"} spacing={1} alignItems="center">
                    <Tooltip title="Customer wallet">
                      <IconButton
                        size="small"
                        color="primary"
                        href={`/app/wallet/ ${
                          giftCart?.user ? giftCart?.user.id : ""
                        }`}
                      >
                        <Icon>account_balance_wallet</Icon>
                      </IconButton>
                    </Tooltip>
                    <Typography variant="body1">
                      {giftCart?.balance !== undefined
                        ? giftCart?.balance + " IQD"
                        : "0"}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
            <Box>
              <RolePermission permission="42-03">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenCallHistoryDialog(true);
                  }}
                >
                  Call History
                </Button>
              </RolePermission>
            </Box>
          </Stack>
        </Box>
      )}

      <Divider />
      <PerfectScrollbar>
        <Box>
          {!giftCart?.giftCartItemByIdDtos ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: { md: "calc(100vh - 360px)", xs: 1000 } }}>
              <DataGrid
                loading={reload}
                rows={giftCart?.giftCartItemByIdDtos ?? []}
                columns={columns}
                rowHeight={200}
                disableSelectionOnClick
                pagination
                paginationMode="client"
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomEmpty,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
