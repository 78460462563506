import {
  GET_ALL_GIFTS_BRAND,
  GET_BY_ID_GIFTS_BRAND,
  GET_GIFTS_BRAND_LIST,
  OPEN_GIFTS_BRAND_DIALOG,
  RELOAD_GIFTS_BRAND,
  RESET_GIFTS_BRAND_FORM,
  SAVE_GIFTS_BRAND_PAGE_NO,
  SAVE_GIFTS_BRAND_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  form: {
    id: null,
    nameAr: "",
    nameEn: "",
    isActive: true,
    attachmentId: "",
  },
  data: [],
  giftItemBrandList: [],
  searchBody: {
    pageSize: 15,
    name: "",
  },
  getById: {},
  saveGiftItemBrandPageNum: 0,
};

function giftItemBrandReducer(giftItemBrand = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_GIFTS_BRAND:
      giftItemBrand.data = payload;
      return giftItemBrand;

    case GET_GIFTS_BRAND_LIST:
      giftItemBrand.giftItemBrandList = payload;
      return giftItemBrand;

    case GET_BY_ID_GIFTS_BRAND:
      giftItemBrand.form.id = payload.id;
      giftItemBrand.form.nameAr = payload.nameAr;
      giftItemBrand.form.nameEn = payload.nameEn;
      giftItemBrand.form.isActive = payload.isActive;
      giftItemBrand.form.imageUrl = payload.attachment?.url;
      giftItemBrand.form.attachmentId = payload.attachment?.id;
      return giftItemBrand;

    case OPEN_GIFTS_BRAND_DIALOG:
      return { ...giftItemBrand, openDialog: !giftItemBrand.openDialog };

    case SAVE_GIFTS_BRAND_PAGE_NO:
      return { ...giftItemBrand, saveGiftItemBrandPageNum: payload };

    case RELOAD_GIFTS_BRAND:
      return { ...giftItemBrand, reload: !giftItemBrand.reload };

    case SAVE_GIFTS_BRAND_SEARCH_BODY:
      return { ...giftItemBrand, searchBody: payload };

    case RESET_GIFTS_BRAND_FORM:
      giftItemBrand.form.id = null;
      giftItemBrand.form.nameEn = "";
      giftItemBrand.form.nameAr = "";
      giftItemBrand.form.isActive = true;
      giftItemBrand.form.attachmentId = "";
      return giftItemBrand;

    default:
      return giftItemBrand;
  }
}

export default giftItemBrandReducer;
